import {EmployeeInfo, EmployeesEndpoint} from "../apina";
import {Component} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {EditEmployeeComponent} from "./edit-employee.component";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {SafePipe} from "../pipes/safe.pipe";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";

@Component({
    templateUrl: "./list-employees.component.html",
    styleUrls: ["./list-employees.component.scss"],
    imports: [
        MatButtonModule,
        MatProgressBarModule,
        MatIconModule,
        SafePipe,
    ],
    standalone: true,
})
export class ListEmployeesComponent {

    employees: EmployeeInfo[];

    constructor(private readonly employeesEndpoint: EmployeesEndpoint,
                private readonly matDialog: MatDialog) {
        this.load();
    }

    private load(): void {
        this.employeesEndpoint.employees().subscribe(e => this.employees = e);
    }

    editEmployee(login: string) {
        this.matDialog.open(EditEmployeeComponent, {data: login})
            .afterClosed().subscribe(() => this.load());
    }
}
