@if (conferenceSheet) {
    <section>

        <div class="caption">
            <a class="pull-left"
               routerLink="/conference/{{conferenceSheet.previousWeek.year}}/{{conferenceSheet.previousWeek.week}}">Week {{ conferenceSheet.previousWeek.week }}</a>
            {{ conferenceSheet.weekOfYear.year }}-{{ conferenceSheet.weekOfYear.week }}
            <a class="pull-right"
               routerLink="/conference/{{conferenceSheet.nextWeek.year}}/{{conferenceSheet.nextWeek.week}}">Week {{ conferenceSheet.nextWeek.week }}</a>
        </div>

        <div class="conference-sheet">
            <div class="clock timeline timeline-clock">
                <div class="header"></div>
                @for (time of timelineSlotTimes; track $index) {
                    <div class="slot" [style.height.px]="timelineSlotHeight">{{ time | localTime }}</div>
                }

            </div>

            @for (day of days; track $index) {
                <div class="timeline timeline-events">
                    <div class="header">
                        <short-day [date]="day.date"></short-day>
                    </div>
                    <div class="content">
                        @for (_ of slotTimes; track $index) {
                            <div class="slot" [style.height.px]="slotHeight"></div>
                        }

                        @for (eventBox of day.eventBoxes; track $index) {
                            <div class="event"
                                 [ngStyle]="eventBox.styles"
                                 [class.focus]="eventBox.focus"
                                 (mouseenter)="eventBox.focus = true"
                                 (mouseleave)="eventBox.focus = false">
                                <time>
                                    @if (eventBox.showStartDay()) {
                                        <span class="day">{{ eventBox.eventStart | shortLocalDate }}</span>
                                    }
                                    {{ eventBox.eventStart | localTime }}
                                    -
                                    @if (eventBox.showEndDay()) {
                                        <span class="day">{{ eventBox.eventEnd | shortLocalDate }}</span>
                                    }
                                    {{ eventBox.eventEnd | localTime }}
                                </time>
                                {{ eventBox.description }}
                            </div>
                        }
                    </div>
                </div>
            }
        </div>
    </section>
} @else {
    <mat-progress-bar/>
}
