import {Injectable} from "@angular/core";
import {Hoursheet, HoursheetEndpoint, SaveHoursRequest} from "../apina";
import {LocalDate} from "js-joda";
import {loginService} from "../auth/login.service";
import {Observable} from "rxjs";
import {mapTo} from "rxjs/operators";

@Injectable({providedIn: "root"})
export class HourService {

    constructor(private hoursheetEndpoint: HoursheetEndpoint) {
    }

    updateHours(taskId: number, date: LocalDate, work: any): Observable<void> {
        const data: SaveHoursRequest = {
            taskId: taskId,
            date: date,
            minutes: work.minutes,
            notes: work.notes
        };

        return this.hoursheetEndpoint.saveHours(loginService.login, data).pipe(mapTo(null));
    }

    getHours(year: number, week: number): Observable<Hoursheet> {
        return this.hoursheetEndpoint.hoursheet(loginService.login, year, week);
    }
}
