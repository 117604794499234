@if (bill) {
    <div class="top">
        <div>
            <a routerLink="/projects/{{projectId}}">Back to project</a>
            <h3>Bill {{ bill.summary.billId }} - {{ bill.summary.createdAt | localDate }}</h3>
        </div>
        <table class="bill-overview">
            <tbody>
            <tr>
                <td>Billing:</td>
                <td>{{ bill.summary.billing | euros }}</td>
            </tr>
            <tr>
                <td>Hours:</td>
                <td>{{ bill.summary.billableTime | duration:"hours+minutes" }}</td>
            </tr>
            <tr>
                <td>Responsible:</td>
                <td>{{ bill.summary.responsibleName }}</td>
            </tr>
            <tr>
                <td>Dates:</td>
                <td>{{ firstDate | localDate }}&mdash;{{ lastDate | localDate }}</td>
            </tr>
            </tbody>
        </table>
    </div>

    <mat-tab-group>
        <mat-tab label="All tasks">
            <table class="default-table full-width">
                <thead>
                <tr>
                    <th>Employee</th>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Role</th>
                    <th>Billing rate</th>
                    <th>Billing</th>
                    <th>Notes</th>
                </tr>
                </thead>
                <tbody>
                    @for (work of bill.tasks; track $index) {
                        <tr>
                            <td class="nowrap">{{ work.employeeName }}</td>
                            <td>{{ work.date | localDate }}</td>
                            <td>{{ work.billableTime | duration }}</td>
                            <td class="nowrap">{{ work.roleTitle }}</td>
                            <td>{{ work.billingRate | euros }}</td>
                            <td>{{ work.billing | euros }}</td>
                            <td>{{ work.notes }}</td>
                        </tr>
                    }
                </tbody>
            </table>
        </mat-tab>
        <mat-tab label="Totals by employees">
            <table class="default-table full-width">
                <thead>
                <tr>
                    <th>Employee</th>
                    <th>Time</th>
                    <th>Billing</th>
                    <th>First date</th>
                    <th>Last date</th>
                </tr>
                </thead>
                <tbody>
                    @for (data of totalsByEmployees; track $index) {
                        <tr>
                            <td>{{ data.employeeName }}</td>
                            <td>{{ data.billableTime | duration }}</td>
                            <td>{{ data.billing | euros }}</td>
                            <td>{{ data.firstDate | localDate }}</td>
                            <td>{{ data.lastDate | localDate }}</td>
                        </tr>
                    }
                </tbody>
            </table>
        </mat-tab>
    </mat-tab-group>
} @else {
    <mat-progress-bar/>
}
