@if (employee) {

    <form class="default-form" name="employeeForm" novalidate #employeeForm="ngForm">
        <h2 mat-dialog-title>Edit {{ login }}</h2>

        <mat-dialog-content>
            <div style="display: flex; flex-direction: column; gap: 8px">
                <mat-form-field floatLabel="always" subscriptSizing="dynamic">
                    <mat-label>Name</mat-label>
                    <input matInput name="employeeName" type="text" [(ngModel)]="employee.name" required>
                </mat-form-field>
                <div class="field-line">
                    <mat-form-field floatLabel="always" subscriptSizing="dynamic">
                        <mat-label>First name</mat-label>
                        <input matInput name="employeeFirstName" type="text" [(ngModel)]="employee.firstName" required>
                    </mat-form-field>
                    <mat-form-field floatLabel="always" subscriptSizing="dynamic">
                        <mat-label>Last name</mat-label>
                        <input matInput name="employeeLastName" type="text" [(ngModel)]="employee.lastName" required>
                    </mat-form-field>
                </div>
                <mat-form-field floatLabel="always" subscriptSizing="dynamic">
                    <mat-label>Email</mat-label>
                    <input matInput name="employeeEmail" type="email" [(ngModel)]="employee.email" required>
                </mat-form-field>
                <mat-form-field floatLabel="always" subscriptSizing="dynamic">
                    <mat-label>OpenID identity</mat-label>
                    <input matInput name="employeeOpenIdIdentity" type="text" [(ngModel)]="employee.openIdIdentity"
                           placeholder="https://www.google.com/accounts/o8/id?id=&lt;id&gt;">
                </mat-form-field>
                <mat-form-field floatLabel="always" subscriptSizing="dynamic">
                    <mat-label>Skype account</mat-label>
                    <input matInput name="employeeSkypeAccount" type="text" [(ngModel)]="employee.skypeAccount">
                </mat-form-field>
                <mat-form-field floatLabel="always" subscriptSizing="dynamic">
                    <mat-label>Phone</mat-label>
                    <input matInput name="employeePhone" type="tel" [(ngModel)]="employee.phone"
                           placeholder="+358 40 123 4567">
                </mat-form-field>
                <mat-form-field floatLabel="always" subscriptSizing="dynamic">
                    <mat-label>Address</mat-label>
                    <input matInput name="employeeAddress" type="text" [(ngModel)]="employee.streetAddress"
                           placeholder="Hatanpään valtatie 2a B">
                </mat-form-field>
                <div class="field-line">
                    <mat-form-field floatLabel="always" subscriptSizing="dynamic">
                        <mat-label>Postal code</mat-label>
                        <input matInput type="text" name="postalCode" [(ngModel)]="employee.postalCode"
                               placeholder="33100" required>
                    </mat-form-field>
                    <mat-form-field floatLabel="always" subscriptSizing="dynamic">
                        <mat-label>Post office</mat-label>
                        <input matInput type="text" name="postOffice" [(ngModel)]="employee.postOffice"
                               placeholder="Tampere" required>
                    </mat-form-field>
                </div>
            </div>

        </mat-dialog-content>

        <mat-dialog-actions align="end">
            <button mat-button (click)="cancel()">Cancel</button>
            <button mat-button (click)="save()" [disabled]="employeeForm.pristine || employeeForm.invalid">Save</button>
        </mat-dialog-actions>
    </form>
} @else {
    <mat-spinner/>
}
