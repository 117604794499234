import {ChangeDetectionStrategy, Component, Inject} from "@angular/core";
import {CustomerProjectRoleDto, EmployeeInfo, EmployeesEndpoint, ProjectsEndpoint} from "../apina";
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {combineLatest, Observable, of as observableOf} from "rxjs";
import {map, shareReplay} from "rxjs/operators";
import {FormsModule} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {MatSelectModule} from "@angular/material/select";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {AsyncPipe} from "@angular/common";

@Component({
    templateUrl: "./edit-project-role.component.html",
    styleUrls: ["./edit-project-role.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        AsyncPipe,
        FormsModule,
        MatButtonModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatProgressSpinnerModule,
    ],
    standalone: true
})
export class EditProjectRoleComponent {

    readonly data$: Observable<ViewData>;

    constructor(private readonly dialogRef: MatDialogRef<EditProjectRoleComponent>,
                private readonly projectsEndpoints: ProjectsEndpoint,
                @Inject(MAT_DIALOG_DATA) private readonly params: EditProjectRoleComponentParams,
                employeesEndpoint: EmployeesEndpoint) {

        let role$: Observable<CustomerProjectRoleDto>;
        if (params.roleId != null) {
            role$ = this.projectsEndpoints.projectRole(params.projectId, params.roleId);
        } else {
            role$ = observableOf({
                name: '',
                billingRate: null,
                employees: []
            });
        }

        const employees$ = employeesEndpoint.employees().pipe(shareReplay(1));

        this.data$ = combineLatest([role$, employees$]).pipe(map(([role, employees]) => ({role, employees})));
    }

    dismiss() {
        this.dialogRef.close();
    }

    save(role: CustomerProjectRoleDto) {
        if (this.params.roleId != null) {
            this.projectsEndpoints.updateProjectRole(this.params.projectId, this.params.roleId, role).subscribe(() => {
                this.dialogRef.close();
            });
        } else {
            this.projectsEndpoints.createProjectRole(this.params.projectId, role).subscribe(() => {
                this.dialogRef.close();
            });
        }
    }
}

interface ViewData {
    role: CustomerProjectRoleDto;
    employees: ReadonlyArray<EmployeeInfo>;
}

export interface EditProjectRoleComponentParams {
    projectId: number;
    roleId?: number;
}
