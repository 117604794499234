import {VacationsEndpoint, VacationSummary} from "../apina";
import {Component} from "@angular/core";
import {RouterLink} from "@angular/router";
import {MatProgressBarModule} from "@angular/material/progress-bar";

@Component({
    templateUrl: "./vacations.component.html",
    imports: [
        RouterLink,
        MatProgressBarModule,
    ],
    standalone: true,
})
export class VacationsComponent {

    vacations: VacationSummary[];

    constructor(vacationsEndpoint: VacationsEndpoint) {
        vacationsEndpoint.vacationSummaries().subscribe(vs => this.vacations = vs);
    }
}
