import {Component, Input} from "@angular/core";
import {formatMinutes} from "../utils/format-utils";

@Component({
    selector: 'app-hour-total',
    template: '<span class="hour-total">{{text()}}</span>',
    standalone: true
})
export class HourTotalComponent {

    @Input() value: number;

    text(): string {
        return this.value === 0 ? "-" : formatMinutes(this.value);
    }
}
