@if (project) {
    <section>
        <h3>{{ project.name }}</h3>

        <mat-tab-group>
            <mat-tab label="Roles">
                <ng-template matTabContent>
                    <table class="default-table full-width">
                        <thead>
                        <tr>
                            <th>Role</th>
                            <th>Billing rate</th>
                            <th>Assignees</th>
                        </tr>
                        </thead>
                        <tbody>
                            @for (role of project.roles; track $index) {
                                <tr>
                                    <td><a href=""
                                           (click)="$event.preventDefault(); editRole(role.id)">{{ role.title }}</a>
                                    </td>
                                    <td>{{ role.billingRate | euros }}</td>
                                    <td>{{ role.assignees.join(", ") }}</td>
                                </tr>
                            }
                        </tbody>
                    </table>

                    <div style="text-align: right; margin-top: 8px">
                        <button mat-flat-button color="primary" (click)="addRole()">Add role</button>
                    </div>
                </ng-template>
            </mat-tab>
            <mat-tab label="Bills">
                <ng-template matTabContent>
                    <table class="default-table full-width">
                        <thead>
                        <tr>
                            <th>Bill</th>
                            <th>Responsible</th>
                            <th>Created</th>
                            <th>Total bill</th>
                            <th>Total time</th>
                        </tr>
                        </thead>
                        <tbody>
                            @for (bill of project.bills; track $index) {
                                <tr>
                                    <td><a
                                        routerLink="/projects/{{project.id}}/bills/{{bill.billId}}">{{ bill.billId }}</a>
                                    </td>
                                    <td>{{ bill.responsibleName }}</td>
                                    <td>{{ bill.createdAt | localDate }}</td>
                                    <td>{{ bill.billing | euros }}</td>
                                    <td>{{ bill.billableTime | duration }}</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </ng-template>
            </mat-tab>
            <mat-tab label="Unbilled work">
                <ng-template matTabContent>
                    @if (project.employeeUnbilledWorkSummaries.length != 0) {
                        <table class="default-table full-width">
                            <thead>
                            <tr>
                                <th>Employee</th>
                                <th>Role</th>
                                <th>First unbilled day</th>
                                <th>Last unbilled day</th>
                                <th>Total time</th>
                                <th>Total amount</th>
                            </tr>
                            </thead>
                            <tbody>
                                @for (work of project.employeeUnbilledWorkSummaries; track $index) {
                                    <tr>
                                        <td>{{ work.employeeName }}</td>
                                        <td>{{ work.roleTitle }}</td>
                                        <td>{{ work.minDate | localDate }}</td>
                                        <td>{{ work.maxDate | localDate }}</td>
                                        <td>{{ work.billableTime | duration }}</td>
                                        <td>{{ work.billing | euros }}</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    }

                    @if (project.unbilledTasks.length !== 0) {
                        <table class="default-table full-width">
                            <thead>
                            <tr>
                                <th>Employee</th>
                                <th>Date</th>
                                <th>Billable time</th>
                                <th>Role</th>
                                <th>Billing rate</th>
                                <th>Billing</th>
                                <th>Notes</th>
                            </tr>
                            </thead>
                            <tbody>
                                @for (work of project.unbilledTasks; track $index) {
                                    <tr>
                                        <td>{{ work.employeeName }}</td>
                                        <td>{{ work.date | localDate }}</td>
                                        <td>{{ work.billableTime | duration }}</td>
                                        <td>{{ work.roleTitle }}</td>
                                        <td>{{ work.billingRate | euros }}</td>
                                        <td>{{ work.billing | euros }}</td>
                                        <td>{{ work.notes }}</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    }

                    @if (project.unbilledTasks.length !== 0) {
                        <div style="text-align: right; margin-top: 8px">
                            <button mat-flat-button color="primary" (click)="showNewBillDialog()">Create a bill</button>
                        </div>

                    } @else {
                        <div style="padding-top: 20px">
                            <p>
                                No unbilled hours.
                            </p>
                        </div>
                    }
                </ng-template>
            </mat-tab>
        </mat-tab-group>
    </section>
} @else {
    <mat-progress-bar/>
}
