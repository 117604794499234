<form class="default-form" role="form" #billForm="ngForm">
    <h2 mat-dialog-title>Create new bill</h2>
    <mat-dialog-content>
        <p>
            On save, unbilled hours until the specified last date will be associated with this bill.
        </p>

        <div style="display: flex; flex-direction: column; gap: 8px">
            <mat-form-field floatLabel="always" subscriptSizing="dynamic">
                <mat-label>Responsible</mat-label>
                <mat-select name="responsible" [(ngModel)]="bill.responsible" required>
                    @for (e of employees; track $index) {
                        <mat-option [value]="e.login">{{ e.name }}</mat-option>
                    }
                </mat-select>
            </mat-form-field>

            <mat-form-field floatLabel="always" subscriptSizing="dynamic">
                <mat-label>Last date</mat-label>
                <input matInput type="date" name="lastIncludedDate" [(ngModel)]="lastIncludedDate" required>
            </mat-form-field>
        </div>

    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button (click)="dismiss()">Cancel</button>
        <button mat-button (click)="save()" [disabled]="!employees || billForm.invalid">Create new bill</button>
    </mat-dialog-actions>
</form>
