import {ProjectsEndpoint} from "../apina";
import {LocalDate} from "js-joda";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";

@Injectable({providedIn: "root"})
export class ProjectService {

    constructor(private projectsEndpoint: ProjectsEndpoint) {
    }

    createBill(projectId: number, responsible: string, lastIncludedDate: LocalDate): Observable<void> {
        return this.projectsEndpoint.createBill(projectId, {
            responsible: responsible,
            lastIncludedDate: lastIncludedDate
        });
    }
}
