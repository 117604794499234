@if (employees) {

    <section>
        <table class="default-table">
            <thead>
            <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>ICQ</th>
                <th>IRC</th>
                <th>Fax</th>
                <th>Skype</th>
            </tr>
            </thead>
            <tbody>

                @for (emp of employees; track emp.id) {
                    <tr>
                        <td class="nowrap">
                            <a href="" (click)="$event.preventDefault(); editEmployee(emp.login)">{{ emp.name }}</a>
                        </td>
                        <td><a href="mailto:{{emp.email}}">{{ emp.email }}</a></td>
                        <td class="nowrap"><a href="tel:{{emp.phone}}">{{ emp.phone }}</a></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td><a [href]="'skype:' + emp.skypeAccount | safe">{{ emp.skypeAccount }}</a></td>
                    </tr>
                }
            </tbody>
        </table>

        <div class="pull-right">
            <a href="/employees.vcf" target="_self" mat-button>
                <mat-icon>perm_contact_calendar</mat-icon>
                vCard export
            </a>
        </div>
    </section>

} @else {
    <mat-progress-bar/>
}
