<mat-toolbar color="primary" class="mat-elevation-z6">
    <span>Yoke</span>
    <nav>
        <a mat-button routerLink="/hoursheet">Hoursheet</a>
        <a mat-button routerLink="/employees">Employees</a>
        <a mat-button [matMenuTriggerFor]="vacations">
            <div style="display: flex; flex-direction: row; align-items: center">
                Vacations
                <mat-icon>arrow_drop_down</mat-icon>
            </div>
        </a>
        <a mat-button routerLink="/projects">Projects</a>
        <a mat-button routerLink="/passwords">Passwords</a>
        <a mat-button routerLink="/conference">Conference</a>
    </nav>
    <span class="spacer"></span>
    <button class="hamburger" mat-icon-button [mat-menu-trigger-for]="fullMenu">
        <mat-icon>menu</mat-icon>
    </button>
</mat-toolbar>

<mat-menu #fullMenu>
    <a mat-menu-item routerLink="/hoursheet">Hoursheet</a>
    <a mat-menu-item routerLink="/employees">Employees</a>
    <a mat-menu-item [matMenuTriggerFor]="vacations">Vacation</a>
    <a mat-menu-item routerLink="/projects">Projects</a>
    <a mat-menu-item routerLink="/passwords">Passwords</a>
    <a mat-menu-item routerLink="/conference">Conference</a>
</mat-menu>

<mat-menu #vacations>
    <a mat-menu-item routerLink="/vacations/{{login}}">My vacations</a>
    <a mat-menu-item routerLink="/vacations">Vacation summary</a>
    <a mat-menu-item routerLink="/vacations/sources">Vacation sources</a>
</mat-menu>

<div class="container">
    <router-outlet/>
</div>
