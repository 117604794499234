import {Component} from "@angular/core";
import {BillDetails, ProjectsEndpoint} from "../apina";
import {Duration, LocalDate} from "js-joda";
import {ActivatedRoute, RouterLink} from "@angular/router";
import {LocalDatePipe} from "../pipes/local-date.pipe";
import {EurosPipe} from "../pipes/euros.pipe";
import {DurationPipe} from "../pipes/duration.pipe";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatTabsModule} from "@angular/material/tabs";

@Component({
    templateUrl: "./view-bill.component.html",
    styleUrls: ["./view-bill.component.scss"],
    imports: [
        RouterLink,
        LocalDatePipe,
        EurosPipe,
        DurationPipe,
        MatTabsModule,
        MatProgressBarModule,
    ],
    standalone: true
})
export class ViewBillComponent {

    billId: number;
    projectId: number;
    bill: BillDetails;
    firstDate: LocalDate;
    lastDate: LocalDate;
    totalsByEmployees: any;

    constructor(route: ActivatedRoute, private projectsEndpoint: ProjectsEndpoint) {
        route.params.forEach(p => {
            this.projectId = +p['projectId'];
            this.billId = +p['billId'];

            this.projectsEndpoint.billDetails(this.projectId, this.billId).subscribe(bill => {
                this.bill = bill;

                const totals: { [id: string]: IEmployeeData } = {};
                let firstDate: LocalDate = null;
                let lastDate: LocalDate = null;

                bill.tasks.forEach(task => {
                    let employeeData = totals[task.employeeId];
                    if (employeeData) {
                        employeeData.billableTime = employeeData.billableTime.plusDuration(task.billableTime);
                        employeeData.billing += task.billing;
                        if (task.date.isBefore(employeeData.firstDate)) {
                            employeeData.firstDate = task.date;
                        }
                        if (task.date.isAfter(employeeData.lastDate)) {
                            employeeData.lastDate = task.date;
                        }
                    } else {
                        employeeData = {
                            employeeName: task.employeeName,
                            billing: task.billing,
                            billableTime: task.billableTime,
                            firstDate: task.date,
                            lastDate: task.date
                        };
                        totals[task.employeeId] = employeeData;
                    }

                    if (!firstDate || task.date.isBefore(firstDate)) {
                        firstDate = task.date;
                    }
                    if (!lastDate || task.date.isAfter(lastDate)) {
                        lastDate = task.date;
                    }
                });

                this.firstDate = firstDate;
                this.lastDate = lastDate;
                this.totalsByEmployees = Object.keys(totals).map(key => totals[key]);
            });
        });
    }
}

interface IEmployeeData {
    employeeName: string;
    billableTime: Duration;
    billing: number;
    firstDate: LocalDate;
    lastDate: LocalDate;
}
