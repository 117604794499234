import {Routes} from "@angular/router";
import {ListEmployeesComponent} from "./employees/list-employees.component";
import {loggedInGuard} from "./auth/logged-in.guard";
import {LoginComponent} from "./auth/login.component";
import {HoursheetComponent} from "./hoursheet/hoursheet.component";
import {VacationsComponent} from "./vacations/vacations.component";
import {EmployeeVacationsComponent} from "./vacations/employee-vacations.component";
import {VacationSourcesComponent} from "./vacations/vacation-sources.component";
import {ListPasswordsComponent} from "./passwords/list-passwords.component";
import {ConferenceComponent} from "./conference/conference.component";
import {ProjectListComponent} from "./projects/project-list.component";
import {ViewProjectComponent} from "./projects/view-project.component";
import {ViewBillComponent} from "./projects/view-bill.component";

export const routes: Routes = [
    {
        path: '',
        redirectTo: '/hoursheet',
        pathMatch: 'full'
    },
    {
        path: 'hoursheet',
        canActivate: [loggedInGuard],
        children: [
            {
                path: ':year/:week',
                component: HoursheetComponent
            },
            {
                path: '',
                component: HoursheetComponent
            }
        ]
    },
    {
        path: 'employees',
        canActivate: [loggedInGuard],
        children: [
            {
                path: '',
                component: ListEmployeesComponent,
                pathMatch: 'full'
            }
        ]
    },
    {
        path: 'vacations',
        canActivate: [loggedInGuard],
        children: [
            {
                path: '',
                component: VacationsComponent,
                pathMatch: 'full'
            },
            {
                path: 'sources',
                pathMatch: 'full',
                component: VacationSourcesComponent
            },
            {
                path: ':login',
                component: EmployeeVacationsComponent
            }
        ]
    },
    {
        path: 'projects',
        canActivate: [loggedInGuard],
        children: [
            {
                path: '',
                pathMatch: 'full',
                component: ProjectListComponent
            },
            {
                path: ':projectId',
                pathMatch: 'full',
                component: ViewProjectComponent
            },
            {
                path: ':projectId/bills/:billId',
                component: ViewBillComponent
            }
        ]
    },
    {
        path: 'passwords',
        canActivate: [loggedInGuard],
        component: ListPasswordsComponent
    },
    {
        path: 'conference',
        canActivate: [loggedInGuard],
        children: [
            {
                path: ':year/:week',
                component: ConferenceComponent
            },
            {
                path: '',
                component: ConferenceComponent
            },
        ]
    },
    {
        path: 'login',
        component: LoginComponent
    }
];
