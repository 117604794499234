import {Duration, LocalDateTime} from "js-joda";
import {Inclusivity} from "./inclusivity";
import {LocalTimeRange} from "./local-time-range";
import {TimeError} from "./time-error";

// TODO: Unit testing

export class LocalDateTimeRange {

    private _start: LocalDateTime;
    private _end: LocalDateTime;

    constructor(start: LocalDateTime, end: LocalDateTime) {
        if (end.isBefore(start)) {
            throw new TimeError('TimeRangeError', `LocalDateTimeRange: End (${end.toString()}) is before start (${start.toString()})`);
        }
        this._start = start;
        this._end = end;
    }

    get start(): LocalDateTime {
        return this._start;
    }

    get end(): LocalDateTime {
        return this._end;
    }

    get duration(): Duration {
        return Duration.between(this.start, this.end);
    }

    toLocalTimeRange(): LocalTimeRange {
        if (!this.start.toLocalDate().isEqual(this.end.toLocalDate()))
            throw "Cannot convert to LocalTimeRange because start and end are on different days";

        return new LocalTimeRange(this.start.toLocalTime(), this.end.toLocalTime());
    }

    overlaps(range: LocalDateTimeRange, inclusivity: Inclusivity = Inclusivity.INCLUSIVE) {
        if (inclusivity === Inclusivity.INCLUSIVE) {
            return !this.start.isAfter(range.end) && !range.start.isAfter(this.end);
        } else {
            return this.start.isBefore(range.end) && range.start.isBefore(this.end);
        }
    }

    contains(dateTime: LocalDateTime, inclusivity: Inclusivity = Inclusivity.INCLUSIVE): boolean {
        if (inclusivity === Inclusivity.INCLUSIVE) {
            return !this.start.isAfter(dateTime) && !dateTime.isAfter(this.end);
        } else {
            return this.start.isBefore(dateTime) && dateTime.isBefore(this.end);
        }
    }

    limit(range: LocalDateTimeRange) {
        return new LocalDateTimeRange(maxLocalTime(this.start, range.start), minLocalTime(this.end, range.end));
    }

    toString(): string {
        return this.start + " - " + this.end;
    }
}


function minLocalTime(t1: LocalDateTime, t2: LocalDateTime): LocalDateTime {
    return t1.isBefore(t2) ? t1 : t2;
}

function maxLocalTime(t1: LocalDateTime, t2: LocalDateTime): LocalDateTime {
    return !t1.isBefore(t2) ? t1 : t2;
}
