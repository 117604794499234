@if (employees) {
    <form class="default-form" role="form" #vacationSourceForm="ngForm">
        <h2 mat-dialog-title>Add new vacation source</h2>

        <mat-dialog-content>
            <div style="display: flex; flex-direction: column; gap: 8px">
                <mat-form-field floatLabel="always" subscriptSizing="dynamic">
                    <mat-label>Name</mat-label>
                    <input matInput name="name" type="text" [(ngModel)]="source.name" required autofocus>
                </mat-form-field>

                <mat-form-field floatLabel="always" subscriptSizing="dynamic">
                    <mat-label>Days</mat-label>
                    <input matInput name="numberOfDays" type="number" [(ngModel)]="source.numberOfDays" required>
                </mat-form-field>

                <mat-form-field floatLabel="always" subscriptSizing="dynamic">
                    <mat-label>Employees</mat-label>
                    <mat-select multiple [(ngModel)]="source.employees" name="source.employees">
                        @for (employee of employees; track $index) {
                            <mat-option [value]="employee.login">{{ employee.name }}</mat-option>
                        }
                    </mat-select>
                </mat-form-field>
            </div>
        </mat-dialog-content>

        <mat-dialog-actions align="end">
            <button mat-button mat-dialog-close>Cancel</button>
            <button mat-button (click)="saveVacationSource()"
                    [disabled]="vacationSourceForm.pristine || vacationSourceForm.invalid">Save
            </button>
        </mat-dialog-actions>
    </form>
} @else {
    <mat-spinner/>
}
