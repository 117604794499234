import {LoginEndpoint} from "../apina";
import {Component} from "@angular/core";
import {loginService} from "./login.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MatProgressBar} from "@angular/material/progress-bar";

@Component({
    template: `<mat-progress-bar/>`,
    imports: [
        MatProgressBar,
    ],
    standalone: true
})
export class LoginComponent {

    constructor(activatedRoute: ActivatedRoute, router: Router, loginEndpoint: LoginEndpoint) {

        const params = activatedRoute.snapshot.queryParams;

        const token = params['token'];
        const returnTo = params['returnTo'];

        if (!token) {
            window.location.assign('/api/oauth2/login?returnTo=' + encodeURIComponent(returnTo));
            return;
        }

        // Now that we have a token, we store it to root scope so that future
        // XHR calls will use the token.
        loginService.authenticationToken = token;

        // Then perform a request to get our user details
        loginEndpoint.loginDetails().subscribe(details => {
            loginService.login = details.login;

            // Finally redirect back to the page where we originally came from
            router.navigateByUrl(returnTo || '/');
        });
    }
}
