import {EmployeeDetails, EmployeesEndpoint} from "../apina";
import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {FormsModule} from "@angular/forms";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";

@Component({
    templateUrl: "./edit-employee.component.html",
    styleUrls: ["./edit-employee.component.scss"],
    imports: [
        FormsModule,
        MatDialogModule,
        MatInputModule,
        MatButtonModule,
        MatProgressSpinnerModule,
    ],
    standalone: true,
})
export class EditEmployeeComponent {

    employee: EmployeeDetails;

    constructor(@Inject(MAT_DIALOG_DATA) readonly login: string,
                private readonly dialogRef: MatDialogRef<EditEmployeeComponent>,
                private readonly employeesEndpoints: EmployeesEndpoint) {
        this.loadEmployee();
    }

    save() {
        this.employeesEndpoints.updateEmployee(this.login, this.employee).subscribe(() => {
            this.dialogRef.close();
        });
    }

    cancel() {
        this.dialogRef.close();
    }

    private loadEmployee() {
        this.employeesEndpoints.employeeDetails(this.login).subscribe(emp => {
            this.employee = emp;
        });
    }
}
