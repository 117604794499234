import {Component, Input} from "@angular/core";
import {DateTimeFormatter, LocalDate} from "js-joda";

const dateFormat = DateTimeFormatter.ofPattern("dd.MM.");

@Component({
    selector: 'local-date',
    template: "<time [attr.datetime]='date'>{{formattedDate}}</time>",
    standalone: true
})
export class LocalDateComponent {
    @Input() date: LocalDate;

    get formattedDate(): string {
        return dateFormat.format(this.date);
    }
}

