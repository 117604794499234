<div class="left-area">
    <h4 class="text-center">
        <button mat-icon-button class="pull-left" (click)="moveBack()">
            <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
        {{ date.year() }}-{{ date.monthValue() }}
        <button mat-icon-button class="pull-right" (click)="moveForward()">
            <mat-icon>keyboard_arrow_right</mat-icon>
        </button>
    </h4>

    <table class="vacations">
        <thead>
        <tr>
            <th>Mon</th>
            <th>Tue</th>
            <th>Wed</th>
            <th>Thu</th>
            <th>Fri</th>
            <th>Sat</th>
            <th>Sun</th>
        </tr>
        </thead>
        <tbody>
            @for (week of calendarWeeks; track $index) {
                <tr>
                    @for (day of week; track $index) {
                        <td>
                            @if (day.date != null) {
                                @if (day.vacation) {
                                    <button mat-button
                                            mat-raised-button
                                            color="primary"
                                            [disabled]="isWeekend(day.date)"
                                            (click)="toggleDay(day)"
                                            [class.saving]="day.progress"
                                            [class.today]="isToday(day.date)"
                                            class="day-button">

                                        @if (!day.progress) {
                                            <span>{{ day.date.dayOfMonth() }}</span>
                                        } @else {
                                            <mat-spinner diameter="19" color="accent"/>
                                        }
                                    </button>

                                } @else {
                                    <button mat-button
                                            mat-stroked-button
                                            color="primary"
                                            [disabled]="isWeekend(day.date)"
                                            (click)="toggleDay(day)"
                                            [class.saving]="day.progress"
                                            [class.today]="isToday(day.date)"
                                            class="day-button">

                                        @if (!day.progress) {
                                            <span>{{ day.date.dayOfMonth() }}</span>
                                        } @else {
                                            <mat-spinner diameter="19" color="accent"/>
                                        }
                                    </button>
                                }
                            }
                        </td>
                    }
                </tr>
            }
        </tbody>
    </table>

    @if (!calendarWeeks) {
        <mat-spinner/>
    }
</div>

<div class="right-area">
    @if (summary) {
        <table class="default-table full-width">
            <tbody>
            <tr>
                <th>Unused</th>
                <td>{{ summary.unusedDays }}</td>
            </tr>
            <tr>
                <th>Used</th>
                <td>{{ summary.usedDays }}</td>
            </tr>
            <tr>
                <th>Total</th>
                <td>{{ summary.totalDays }}</td>
            </tr>
            </tbody>
        </table>
    } @else {
        <mat-spinner/>
    }

    @if (sources) {
        <table class="default-table full-width">
            <thead>
            <tr>
                <th>Name</th>
                <th>Number of days</th>
            </tr>
            </thead>
            <tbody>
                @for (source of sources; track $index) {
                    <tr>
                        <td>{{ source.name }}</td>
                        <td>{{ source.numberOfDays }}</td>
                    </tr>
                }
            </tbody>
        </table>
    } @else {
        <mat-spinner/>
    }
</div>
