<form class="default-form" role="form" id="projectForm" #projectForm="ngForm">
    <h2 mat-dialog-title>New project</h2>
    <mat-dialog-content>
        <mat-form-field floatLabel="always" subscriptSizing="dynamic">
            <mat-label>Name</mat-label>
            <input matInput name="projectName" type="text" [(ngModel)]="project.name" autofocus required>
        </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button (click)="dismiss()">Cancel</button>
        <button mat-button form="projectForm" (click)="save()" [disabled]="projectForm.pristine || projectForm.invalid">Create project</button>
    </mat-dialog-actions>
</form>
