import {Component} from "@angular/core";
import {CreateVacationSourceDto, EmployeeInfo, EmployeesEndpoint, VacationsEndpoint} from "../apina";
import {MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {FormsModule} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatSelectModule} from "@angular/material/select";

@Component({
    templateUrl: "./new-vacation-source.component.html",
    imports: [
        FormsModule,
        MatButtonModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatProgressSpinnerModule,
    ],
    standalone: true,
})
export class NewVacationSourceComponent {

    employees: EmployeeInfo[];
    source: CreateVacationSourceDto = {
        name: '',
        numberOfDays: null,
        employees: []
    };

    constructor(employeesEndpoint: EmployeesEndpoint,
                private readonly dialogRef: MatDialogRef<NewVacationSourceComponent>,
                private readonly vacationsEndpoint: VacationsEndpoint) {
        employeesEndpoint.employees().subscribe(emps => {
            this.employees = emps;
        });
    }

    saveVacationSource() {
        this.vacationsEndpoint.saveVacationSource(this.source).subscribe(() => {
            this.dialogRef.close();
        });
    }

    protected readonly indexedDB = indexedDB;
}
