@if (data$ | async; as data) {
    <form class="default-form" #roleForm="ngForm" role="form">
        <h2 mat-dialog-title>Project role</h2>
        <mat-dialog-content>

            <div style="display: flex; flex-direction: column; gap: 8px">
                <mat-form-field floatLabel="always" subscriptSizing="dynamic">
                    <mat-label>Name</mat-label>
                    <input matInput name="name" type="text" [(ngModel)]="data.role.name" required autofocus>
                </mat-form-field>

                <mat-form-field floatLabel="always" subscriptSizing="dynamic">
                    <mat-label>Billing rate</mat-label>
                    <input matInput name="billingRate" type="number" [(ngModel)]="data.role.billingRate" required
                           step="any">
                </mat-form-field>

                <mat-form-field floatLabel="always" subscriptSizing="dynamic">
                    <mat-label>Employees</mat-label>
                    <mat-select multiple [(ngModel)]="data.role.employees" name="selectedEmployees">
                        @for (employee of data.employees; track $index) {
                            <mat-option [value]="employee.login">{{ employee.name }}</mat-option>
                        }
                    </mat-select>
                </mat-form-field>
            </div>

        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <button mat-button (click)="dismiss()">Cancel</button>
            <button mat-button (click)="save(data.role)" [disabled]="roleForm.invalid">Save</button>
        </mat-dialog-actions>
    </form>
} @else {
    <mat-spinner/>
}
