import {ApinaConfig} from "./apina";
import {WeekOfYear} from "./utils/time/week-of-year";
import {Duration, LocalDate, LocalDateTime, LocalTime} from "js-joda";

export function createApinaConfig(): ApinaConfig {
    const config = new ApinaConfig();

    config.registerSerializer('WeekOfYear', {
        serialize: (o: WeekOfYear | null) => o && o.toISOString(),
        deserialize: (o: string | null ) => o && WeekOfYear.parseISO(o)
    });
    config.registerSerializer('LocalDate', {
        serialize: (o: LocalDate | null) => o && o.toString(),
        deserialize: (o: string | null) => o && LocalDate.parse(o)
    });
    config.registerSerializer('LocalTime', {
        serialize: (o: LocalTime | null) => o && o.toString(),
        deserialize: (o: string | null) => o && LocalTime.parse(o)
    });
    config.registerSerializer('LocalDateTime', {
        serialize: (o: LocalDateTime | null) => o && o.toString(),
        deserialize: (o: string | null) => o && LocalDateTime.parse(o)
    });
    config.registerSerializer('Duration', {
        serialize: (o: Duration | null) => o && o.toMillis(),
        deserialize: (o: number | null) => o && Duration.ofMillis(o)
    });

    return config;
}
