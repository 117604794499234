import {Pipe, PipeTransform} from "@angular/core";
import {Duration} from "js-joda";

@Pipe({
    name: 'duration',
    pure: true,
    standalone: true,
})
export class DurationPipe implements PipeTransform {
    transform(value: Duration | null, format = "days+hours+minutes"): string | null {

        if (!value) return null;

        const totalMinutes = value.toMinutes();
        const parts = [];

        const minutes = totalMinutes % 60;

        if (format.includes("days")) {
            const hours = Math.floor(totalMinutes / 60) % 24;
            const days = Math.floor(totalMinutes / (60 * 24));

            if (days !== 0)
                parts.push(`${days}d`);

            if (hours !== 0)
                parts.push(`${hours}h`);
            if (minutes !== 0 || (days === 0 && hours === 0))
                parts.push(`${hours}m`);

        } else {
            const hours = Math.floor(totalMinutes / 60);

            if (hours !== 0)
                parts.push(`${hours}h`);
            if (minutes !== 0 || hours === 0)
                parts.push(`${hours}m`);
        }

        return parts.join(" ");
    }
}
