import {Inclusivity} from "./inclusivity";
import {Duration, LocalDate, LocalDateTime, LocalTime} from "js-joda";
import {LocalDateTimeRange} from "./local-date-time-range";
import {TimeError} from "./time-error";
import {checkValidHourInDay} from "./utils";

// TODO: Unit testing

export class LocalTimeRange {

    static betweenHours(start: number, end: number) {
        checkValidHourInDay(start);
        checkValidHourInDay(end);

        return new LocalTimeRange(LocalTime.of(start), LocalTime.of(end));
    }

    constructor(readonly start: LocalTime, readonly end: LocalTime) {
        if (end.isBefore(start))
            throw new TimeError('TimeRangeError', `LocalTimeRange: End (${end.toString()}) is before start (${start.toString()})`);
    }

    atDate(date: LocalDate) {
        return new LocalDateTimeRange(LocalDateTime.of(date, this.start), LocalDateTime.of(date, this.end));
    }

    overlaps(range: LocalTimeRange, inclusivity: Inclusivity = Inclusivity.INCLUSIVE): boolean {
        if (inclusivity === Inclusivity.INCLUSIVE) {
            return !this.start.isAfter(range.end) && !range.start.isAfter(this.end);
        } else {
            return this.start.isBefore(range.end) && range.start.isBefore(this.end);
        }
    }

    contains(time: LocalTime, inclusivity: Inclusivity = Inclusivity.INCLUSIVE): boolean {
        if (inclusivity === Inclusivity.INCLUSIVE) {
            return !this.start.isAfter(time) && !time.isAfter(this.end);
        } else {
            return this.start.isBefore(time) && time.isBefore(this.end);
        }
    }

    get duration(): Duration {
        return Duration.between(this.start, this.end);
    }

    toString(): string {
        return this.start + " - " + this.end;
    }
}
