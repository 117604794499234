<h2 mat-dialog-title>Confirm deletion</h2>

<mat-dialog-content>
    <p>
        Are you sure you want to delete password <q>{{password.title}}</q>?
    </p>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button (click)="dismiss()">Cancel</button>
    <button mat-button (click)="confirmDelete()">Delete</button>
</mat-dialog-actions>


