import {Component, Input, OnInit} from "@angular/core";
import {HourService} from "./hour.service";
import {LocalDate} from "js-joda";
import {formatMinutes} from "../utils/format-utils";
import {FormsModule} from "@angular/forms";

@Component({
    selector: 'app-hour-editor',
    templateUrl: "./hour-editor.component.html",
    styleUrls: ["./hour-editor.component.scss"],
    imports: [
        FormsModule,
    ],
    standalone: true
})
export class HourEditorComponent implements OnInit {

    @Input() taskId: any;
    @Input() date: LocalDate;
    @Input() hourModel: { minutes: number, notes: string };
    valid = true;
    modified = false;
    inputText = '';

    constructor(private hourService: HourService) {
    }

    ngOnInit() {
        this.inputText = formatMinutes(this.hourModel.minutes);
    }

    focusLost() {
        this.change();
        if (this.modified && this.valid) {
            this.hourService.updateHours(this.taskId, this.date, this.hourModel).subscribe(() => {
                this.modified = false;
            });
        }
    }

    change() {
        const parsed = parseMinutes(this.inputText);
        if (parsed === undefined) {
            this.valid = false;
        } else {
            this.valid = true;
            if (parsed !== this.hourModel.minutes) {
                this.modified = true;
                this.hourModel.minutes = parsed;
            }
        }
    }

    keyPressed(e: KeyboardEvent): void {
        if (e.keyCode === 13) {
            e.preventDefault();

            const work = this.hourModel;
            const notes = prompt("Enter extra notes for work", work.notes);
            if (notes !== null && notes !== work.notes) {
                this.modified = true;
                work.notes = notes;
            }
        }
    }
}

function parseMinutes(text: string) {
    if (text === '') return null;
    const separator = text.indexOf(':');

    let hours: number;
    let minutes: number;
    if (separator !== -1) {
        hours = parseInt(text.substring(0, separator), 10);
        minutes = parseInt(text.substring(separator + 1), 10);
    } else {
        hours = parseInt(text, 10);
        minutes = 0;
    }

    if (hours < 24 && minutes < 60) {
        return hours * 60 + minutes;
    } else {
        return undefined;
    }
}
