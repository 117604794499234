import {Component} from "@angular/core";
import {PasswordInfo, SavedPasswordEndpoint} from "../apina";
import {AddPasswordComponent} from "./add-password.component";
import {EditPasswordComponent} from "./edit-password.component";
import {DeletePasswordComponent} from "./delete-password.component";
import {MatDialog} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import {FormsModule} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatIconModule} from "@angular/material/icon";

interface IExtendedPasswordInfo extends PasswordInfo {
    password?: string;
    loading?: boolean;
}

@Component({
    templateUrl: "./list-passwords.component.html",
    styleUrls: ["./list-passwords.component.scss"],
    imports: [
        FormsModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatProgressBarModule,
        MatIconModule,
    ],
    standalone: true,
})
export class ListPasswordsComponent {

    private _filter = '';
    passwords: IExtendedPasswordInfo[] = null;
    filteredPasswords: IExtendedPasswordInfo[] = null;

    constructor(private readonly savedPasswordEndpoints: SavedPasswordEndpoint,
                private readonly matDialog: MatDialog) {
        this.loadPasswords();
    }

    togglePassword(password: IExtendedPasswordInfo) {
        if (password.password) {
            password.password = null;
        } else {
            this.loadPassword(password.id, password);
        }
    }

    showAddForm() {
        const dialogRef = this.matDialog.open(AddPasswordComponent);
        dialogRef.afterClosed().subscribe(() => this.loadPasswords());
    }

    showEditForm(password: PasswordInfo) {
        const dialogRef = this.matDialog.open(EditPasswordComponent, {data: password.id});
        dialogRef.afterClosed().subscribe(() => this.loadPasswords());
    }

    deletePassword(password: PasswordInfo) {
        const dialogRef = this.matDialog.open(DeletePasswordComponent, {data: password});
        dialogRef.afterClosed().subscribe(() => this.loadPasswords());
    }

    private loadPassword(id: number, password: IExtendedPasswordInfo) {
        password.loading = true;

        this.savedPasswordEndpoints.getPasswordDetails(id).subscribe(pw => {
            password.loading = false;

            password.title = pw.title;
            password.username = pw.username;
            password.password = pw.password;
            password.notes = pw.notes;
        });
    }

    get filter() {
        return this._filter;
    }

    set filter(value: string) {
        if (value !== this._filter) {
            this._filter = value;
            this.refreshFilteredPasswords();
        }
    }

    loadPasswords() {
        this.savedPasswordEndpoints.listPasswords().subscribe(p => {
            this.passwords = p;
            this.refreshFilteredPasswords();
        });
    }

    private refreshFilteredPasswords() {
        if (this.passwords == null || this.filter == null) return;

        const filter = this.filter.toLowerCase();
        this.filteredPasswords = this.passwords.filter(pw => pw.title.toLowerCase().indexOf(filter) !== -1);
    }
}
