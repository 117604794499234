import {Pipe, PipeTransform} from "@angular/core";

const currencyFormatter = new Intl.NumberFormat('fi-FI', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2
});

@Pipe({
    name: 'euros',
    pure: true,
    standalone: true
})
export class EurosPipe implements PipeTransform {
    transform(value: number) {
        return value && currencyFormatter.format(value);
    }
}
