import {Component} from "@angular/core";
import {HourService} from "./hour.service";
import {BillableWork, Hoursheet} from "../apina";
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {WeekOfYear} from "../utils/time/week-of-year";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {HourTotalComponent} from "./hour-total.component";
import {HourEditorComponent} from "./hour-editor.component";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {FormsModule} from "@angular/forms";
import {LocalDateComponent} from "../components/local-date.component";

@Component({
    templateUrl: "./hoursheet.component.html",
    styleUrls: ["./hoursheet.component.scss"],
    imports: [
        FormsModule,
        RouterLink,
        MatCheckboxModule,
        HourTotalComponent,
        HourEditorComponent,
        LocalDateComponent,
        MatProgressBarModule,
    ],
    standalone: true,
})
export class HoursheetComponent {

    static days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

    showWeekend = false;
    hoursheet: Hoursheet;

    constructor(route: ActivatedRoute,
                router: Router,
                private hourService: HourService) {

        route.params.forEach(p => {
            const year = p['year'];
            const week = p['week'];

            if (year === undefined || week === undefined) {
                const weekOfYear = WeekOfYear.now();
                router.navigate(["/hoursheet", weekOfYear.year, weekOfYear.week]);
                return;
            }

            this.hourService.getHours(year, week).subscribe(h => this.hoursheet = h);
        });
    }

    dayTotal(day: any) {
        let sum = 0;
        for (const task of this.hoursheet.tasks)
            sum += normalize(((task as any)[day] as BillableWork).minutes);
        return sum;
    }

    total() {
        let sum = 0;
        for (const day of HoursheetComponent.days)
            sum += this.dayTotal(day);
        return sum;
    }

    taskTotal(task: any) {
        let sum = 0;
        for (const day of HoursheetComponent.days)
            sum += normalize(task[day].minutes);
        return sum;
    }
}

function normalize(x: any): number {
    const result = parseFloat(x);
    return isNaN(result) ? 0 : result;
}
