@if (vacations) {
    <table class="default-table full-width">
        <thead>
        <tr>
            <th>Name</th>
            <th>Total days</th>
            <th>Used days</th>
            <th>Unused days</th>
        </tr>
        </thead>
        <tbody>
            @for (vacation of vacations; track $index) {
                <tr>
                    <td><a routerLink="/vacations/{{vacation.employeeLogin}}">{{ vacation.employeeName }}</a></td>
                    <td>{{ vacation.totalDays }}</td>
                    <td>{{ vacation.usedDays }}</td>
                    <td>{{ vacation.unusedDays }}</td>
                </tr>
            }
        </tbody>
    </table>
} @else {
    <mat-progress-bar/>
}
