import {Component} from "@angular/core";
import {EmployeeVacationSourceSummary, VacationsEndpoint, VacationSummary} from "../apina";
import {ActivatedRoute} from "@angular/router";
import {DayOfWeek, LocalDate, YearMonth} from "js-joda";
import {MatButtonModule} from "@angular/material/button";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatIconModule} from "@angular/material/icon";

@Component({
    templateUrl: "./employee-vacations.component.html",
    styleUrls: ["./employee-vacations.component.scss"],
    imports: [
        MatButtonModule,
        MatIconModule,
        MatProgressSpinnerModule,
    ],
    standalone: true,
})
export class EmployeeVacationsComponent {

    login: string;
    sources: EmployeeVacationSourceSummary[];
    summary: VacationSummary;
    date = YearMonth.now();
    calendarWeeks: CalendarDay[][] | null = null;

    constructor(route: ActivatedRoute, private vacationsEndpoint: VacationsEndpoint) {
        route.params.forEach(p => {
            this.login = p['login'];

            this.vacationsEndpoint.employeeVacationSourceSummaries(this.login).subscribe(sources => this.sources = sources);
            this.vacationsEndpoint.employeeSummary(this.login).subscribe(summary => this.summary = summary);

            this.dateChanged();
        });
    }

    toggleDay(day: CalendarDay) {
        const newState = !day.vacation;
        const date = day.date;

        const year = date.year();
        const month = date.monthValue();
        const dayOfMonth = date.dayOfMonth();
        const stateName = newState ? "vacation" : "work";

        day.progress = true;

        this.vacationsEndpoint.toggleVacation(this.login, year, month, dayOfMonth, stateName).subscribe(result => {
            this.summary = result;
            day.vacation = newState;
            day.progress = false;
        });
    }

    moveBack() {
        this.date = this.date.minusMonths(1);
        this.calendarWeeks = null;

        this.dateChanged();
    }

    moveForward() {
        this.date = this.date.plusMonths(1);
        this.calendarWeeks = null;

        this.dateChanged();
    }

    isWeekend(date: LocalDate) {
        return isWeekend(date.dayOfWeek());
    }

    isToday(date: LocalDate) {
        return LocalDate.now().isEqual(date);
    }

    private dateChanged() {
        this.vacationsEndpoint.calendar(this.login, this.date.year(), this.date.monthValue()).subscribe(calendar => {
            const month = this.date.month();
            let date = this.date.atDay(1);

            const weeks: CalendarDay[][] = [];
            let currentWeek: CalendarDay[] = [{}, {}, {}, {}, {}, {}, {}];

            while (date.month() === month) {
                const weekDay = date.dayOfWeek();

                const vacation = isWeekend(weekDay) || calendar.days.indexOf(date.dayOfMonth()) !== -1;
                currentWeek[weekDay.ordinal()] = {
                    date: date,
                    vacation: vacation,
                    progress: false
                };

                if (weekDay === DayOfWeek.SUNDAY) {
                    weeks.push(currentWeek);
                    currentWeek = [{}, {}, {}, {}, {}, {}, {}];
                }

                date = date.plusDays(1);
            }

            weeks.push(currentWeek);

            this.calendarWeeks = weeks;
        });
    }
}

interface CalendarDay {
    date?: LocalDate;
    vacation?: boolean;
    progress?: boolean;
}

function isWeekend(dayOfWeek: DayOfWeek) {
    return dayOfWeek === DayOfWeek.SATURDAY || dayOfWeek === DayOfWeek.SUNDAY;
}
