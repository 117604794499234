@if (vacationSources) {
    <section>
        <table class="default-table full-width">
            <thead>
            <tr>
                <th>Name</th>
                <th>Total days</th>
                <th>Created</th>
                <th>Creator</th>
                <th>Assigned to</th>
            </tr>
            </thead>

            <tbody>
                @for (source of vacationSources; track $index) {
                    <tr>
                        <td>{{ source.name }}</td>
                        <td>{{ source.numberOfDays }}</td>
                        <td>
                            <time>{{ source.created | localDate }}</time>
                        </td>
                        <td class="nowrap">{{ source.creator }}</td>
                        <td>{{ source.assignees.join(", ") }}</td>
                    </tr>
                }
            </tbody>
        </table>

        <button class="default-fab" mat-fab color="primary" (click)="add()">
            <mat-icon>add</mat-icon>
        </button>
    </section>
} @else {
    <mat-progress-bar/>
}
