import {Component, Input} from "@angular/core";
import {LocalDate} from "js-joda";
import {ShortLocalDatePipe} from "../pipes/short-local-date.pipe";

@Component({
    selector: 'short-day',
    template: `
        {{dayName}} <time>{{date | shortLocalDate}}</time>
    `,
    imports: [
        ShortLocalDatePipe
    ],
    standalone: true
})
export class ShortDayComponent {
    @Input() date: LocalDate;

    get dayName(): string {
        const name = this.date.dayOfWeek().name();
        return name.charAt(0).toUpperCase() + name.substring(1, 3).toLowerCase();
    }
}
