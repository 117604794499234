import {LocalTimeRange} from "./time";
import {LocalTime} from "js-joda";

export function createLocalTimeStepsBetween(range: LocalTimeRange, minutesStep: number): LocalTime[] {
    const result: LocalTime[] = [];
    for (let time = range.start; time.isBefore(range.end); time = time.plusMinutes(minutesStep)) {
        result.push(time);
    }
    return result;
}
