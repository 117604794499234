import "./polyfills";
import {bootstrapApplication} from "@angular/platform-browser";
import {AppComponent} from "./app.component";
import {provideAnimationsAsync} from "@angular/platform-browser/animations/async";
import {provideApina} from "./apina";
import {MyApinaEndpointContext} from "./auth/apina-endpoint-context";
import {provideHttpClient} from "@angular/common/http";
import {createApinaConfig} from "./apina.config";
import {provideRouter} from "@angular/router";
import {routes} from "./app.routing";

bootstrapApplication(AppComponent, {
    providers: [
        provideHttpClient(),
        provideAnimationsAsync(),
        provideRouter(routes),
        provideApina({
            config: createApinaConfig(),
            endpointContextClass: MyApinaEndpointContext,
        }),
    ]
});
