import {Component} from "@angular/core";
import {PasswordForm, SavedPasswordEndpoint} from "../apina";
import {generatePassword} from "./password-utils";
import {MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {FormsModule} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";

@Component({
    templateUrl: "./add-password.component.html",
    imports: [
        FormsModule,
        MatButtonModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatProgressSpinnerModule,
    ],
    standalone: true,
})
export class AddPasswordComponent {

    form: PasswordForm = {
        title: '',
        username: '',
        password: '',
        notes: ''
    };

    constructor(private readonly dialogRef: MatDialogRef<AddPasswordComponent>,
                private readonly savedPasswordEndpoints: SavedPasswordEndpoint) {
    }

    savePassword() {
        this.savedPasswordEndpoints.addPassword(this.form).subscribe(() => {
            this.dialogRef.close();
        });
    }

    dismiss() {
        this.dialogRef.close();
    }

    generatePassword() {
        this.form.password = generatePassword();
    }
}
