import {inject} from "@angular/core";
import {CanActivateFn, Router} from "@angular/router";
import {loginService} from "./login.service";

export const loggedInGuard: CanActivateFn = (_route, state) => {
    if (loginService.authenticationToken != null)
        return true;

    const router = inject(Router);
    router.navigate(['/login'], { queryParams: { returnTo: state.url } });
    return false;
};
