/* tslint:disable */
import { WeekOfYear } from './utils/time';
import { Duration, LocalDate, LocalDateTime } from 'js-joda';

import { Injectable, Provider, Type } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface Dictionary<V> {
    [key: string]: V;
}

export type ResponseType = string;


export interface Attachment {
    authorIcon: string | null;
    authorLink: string | null;
    authorName: string | null;
    color: string | null;
    fallback: string | null;
    fields: Field[];
    imageUrl: string | null;
    pretext: string | null;
    text: string | null;
    thumbUrl: string | null;
    title: string | null;
    titleLink: string | null;
}

export interface BillDetails {
    summary: BillSummary;
    tasks: EmployeeTaskInfo[];
}

export interface BillSummary {
    billId: number;
    billableTime: Duration | null;
    billing: number | null;
    createdAt: LocalDate | null;
    responsibleName: string | null;
}

export interface BillableWork {
    minutes: number | null;
    notes: string;
}

export interface CalendarEvent {
    description: string | null;
    end: LocalDateTime;
    start: LocalDateTime;
}

export interface ConferenceSheet {
    events: CalendarEvent[];
    weekOfYear: WeekOfYear;
}

export interface CreateBillRequest {
    lastIncludedDate: LocalDate;
    responsible: string;
}

export interface CreateCustomerProjectRequest {
    name: string;
}

export interface CreateVacationSourceDto {
    employees: string[];
    name: string;
    numberOfDays: number;
}

export interface CustomerProjectRole {
    assignees: string[];
    billingRate: number;
    id: number;
    title: string;
}

export interface CustomerProjectRoleDto {
    billingRate: number;
    employees: string[];
    name: string;
}

export interface EmployeeDetails {
    bitbucketLogin: string | null;
    cvId: string | null;
    email: string | null;
    firstName: string | null;
    ircNick: string | null;
    lastName: string | null;
    name: string | null;
    openIdIdentity: string | null;
    phone: string | null;
    postOffice: string | null;
    postalCode: string | null;
    skypeAccount: string | null;
    streetAddress: string | null;
}

export interface EmployeeInfo {
    active: boolean;
    bitbucketLogin: string | null;
    email: string | null;
    firstName: string | null;
    id: number;
    ircNick: string | null;
    lastName: string | null;
    login: string | null;
    name: string | null;
    phone: string | null;
    postOffice: string | null;
    postalCode: string | null;
    skypeAccount: string | null;
    streetAddress: string | null;
}

export interface EmployeeTaskInfo {
    billableTime: Duration | null;
    billing: number | null;
    billingRate: number | null;
    date: LocalDate | null;
    employeeId: number;
    employeeName: string | null;
    notes: string | null;
    roleId: number;
    roleTitle: string | null;
    workId: number;
}

export interface EmployeeUnbilledWorkSummary {
    billableTime: Duration | null;
    billing: number | null;
    employeeId: number;
    employeeName: string | null;
    maxDate: LocalDate | null;
    minDate: LocalDate | null;
    roleTitle: string | null;
}

export interface EmployeeVacationSourceSummary {
    name: string | null;
    numberOfDays: number;
}

export interface Field {
    short: boolean;
    title: string;
    value: string;
}

export interface Hoursheet {
    fridayDate: LocalDate;
    mondayDate: LocalDate;
    nextWeek: WeekOfYear;
    previousWeek: WeekOfYear;
    saturdayDate: LocalDate;
    sundayDate: LocalDate;
    tasks: TaskWeekHours[];
    thursdayDate: LocalDate;
    tuesdayDate: LocalDate;
    wednesdayDate: LocalDate;
    weekOfYear: WeekOfYear;
}

export interface LoginDetails {
    firstName: string;
    lastName: string;
    login: string;
    name: string;
}

export interface NewErrorLogEntryDto {
    details: string | null;
    extra: string | null;
    message: string;
    username: string | null;
}

export interface PasswordDetails {
    id: number;
    notes: string | null;
    password: string | null;
    title: string | null;
    username: string | null;
}

export interface PasswordForm {
    notes: string | null;
    password: string | null;
    title: string | null;
    username: string | null;
}

export interface PasswordInfo {
    id: number;
    notes: string | null;
    title: string | null;
    username: string | null;
}

export interface ProjectDetails {
    bills: BillSummary[];
    employeeUnbilledWorkSummaries: EmployeeUnbilledWorkSummary[];
    id: number;
    name: string;
    roles: CustomerProjectRole[];
    unbilledTasks: EmployeeTaskInfo[];
}

export interface ProjectId {
    id: number;
}

export interface ProjectSummary {
    billed: number;
    id: number;
    name: string | null;
    totalBilling: number;
    unbilled: number;
}

export interface SaveHoursRequest {
    date: LocalDate;
    minutes: number | null;
    notes: string;
    taskId: number;
}

export interface SaveHoursResponse {
    minutes: number | null;
}

export interface SlashCommandResponse {
    attachments: Attachment[];
    responseType: ResponseType;
    text: string | null;
    username: string | null;
}

export interface TaskWeekHours {
    friday: BillableWork;
    id: number;
    monday: BillableWork;
    name: string;
    projectId: number;
    projectName: string;
    saturday: BillableWork;
    sunday: BillableWork;
    thursday: BillableWork;
    tuesday: BillableWork;
    wednesday: BillableWork;
}

export interface VacationCalendar {
    days: number[];
    month: number;
    year: number;
}

export interface VacationSourceSummary {
    assignees: string[];
    created: LocalDate;
    creator: string;
    name: string;
    numberOfDays: number;
}

export interface VacationSummary {
    employeeId: number;
    employeeLogin: string;
    employeeName: string;
    totalDays: number;
    unusedDays: number;
    usedDays: number;
}

export function registerDefaultSerializers(config: ApinaConfig) {
    config.registerIdentitySerializer('ResponseType');


    config.registerClassSerializer('Attachment', {
        'authorIcon': 'string',
        'authorLink': 'string',
        'authorName': 'string',
        'color': 'string',
        'fallback': 'string',
        'fields': 'Field[]',
        'imageUrl': 'string',
        'pretext': 'string',
        'text': 'string',
        'thumbUrl': 'string',
        'title': 'string',
        'titleLink': 'string'
    });

    config.registerClassSerializer('BillDetails', {
        'summary': 'BillSummary',
        'tasks': 'EmployeeTaskInfo[]'
    });

    config.registerClassSerializer('BillSummary', {
        'billId': 'number',
        'billableTime': 'Duration',
        'billing': 'number',
        'createdAt': 'LocalDate',
        'responsibleName': 'string'
    });

    config.registerClassSerializer('BillableWork', {
        'minutes': 'number',
        'notes': 'string'
    });

    config.registerClassSerializer('CalendarEvent', {
        'description': 'string',
        'end': 'LocalDateTime',
        'start': 'LocalDateTime'
    });

    config.registerClassSerializer('ConferenceSheet', {
        'events': 'CalendarEvent[]',
        'weekOfYear': 'WeekOfYear'
    });

    config.registerClassSerializer('CreateBillRequest', {
        'lastIncludedDate': 'LocalDate',
        'responsible': 'string'
    });

    config.registerClassSerializer('CreateCustomerProjectRequest', {
        'name': 'string'
    });

    config.registerClassSerializer('CreateVacationSourceDto', {
        'employees': 'string[]',
        'name': 'string',
        'numberOfDays': 'number'
    });

    config.registerClassSerializer('CustomerProjectRole', {
        'assignees': 'string[]',
        'billingRate': 'number',
        'id': 'number',
        'title': 'string'
    });

    config.registerClassSerializer('CustomerProjectRoleDto', {
        'billingRate': 'number',
        'employees': 'string[]',
        'name': 'string'
    });

    config.registerClassSerializer('EmployeeDetails', {
        'bitbucketLogin': 'string',
        'cvId': 'string',
        'email': 'string',
        'firstName': 'string',
        'ircNick': 'string',
        'lastName': 'string',
        'name': 'string',
        'openIdIdentity': 'string',
        'phone': 'string',
        'postOffice': 'string',
        'postalCode': 'string',
        'skypeAccount': 'string',
        'streetAddress': 'string'
    });

    config.registerClassSerializer('EmployeeInfo', {
        'active': 'boolean',
        'bitbucketLogin': 'string',
        'email': 'string',
        'firstName': 'string',
        'id': 'number',
        'ircNick': 'string',
        'lastName': 'string',
        'login': 'string',
        'name': 'string',
        'phone': 'string',
        'postOffice': 'string',
        'postalCode': 'string',
        'skypeAccount': 'string',
        'streetAddress': 'string'
    });

    config.registerClassSerializer('EmployeeTaskInfo', {
        'billableTime': 'Duration',
        'billing': 'number',
        'billingRate': 'number',
        'date': 'LocalDate',
        'employeeId': 'number',
        'employeeName': 'string',
        'notes': 'string',
        'roleId': 'number',
        'roleTitle': 'string',
        'workId': 'number'
    });

    config.registerClassSerializer('EmployeeUnbilledWorkSummary', {
        'billableTime': 'Duration',
        'billing': 'number',
        'employeeId': 'number',
        'employeeName': 'string',
        'maxDate': 'LocalDate',
        'minDate': 'LocalDate',
        'roleTitle': 'string'
    });

    config.registerClassSerializer('EmployeeVacationSourceSummary', {
        'name': 'string',
        'numberOfDays': 'number'
    });

    config.registerClassSerializer('Field', {
        'short': 'boolean',
        'title': 'string',
        'value': 'string'
    });

    config.registerClassSerializer('Hoursheet', {
        'fridayDate': 'LocalDate',
        'mondayDate': 'LocalDate',
        'nextWeek': 'WeekOfYear',
        'previousWeek': 'WeekOfYear',
        'saturdayDate': 'LocalDate',
        'sundayDate': 'LocalDate',
        'tasks': 'TaskWeekHours[]',
        'thursdayDate': 'LocalDate',
        'tuesdayDate': 'LocalDate',
        'wednesdayDate': 'LocalDate',
        'weekOfYear': 'WeekOfYear'
    });

    config.registerClassSerializer('LoginDetails', {
        'firstName': 'string',
        'lastName': 'string',
        'login': 'string',
        'name': 'string'
    });

    config.registerClassSerializer('NewErrorLogEntryDto', {
        'details': 'string',
        'extra': 'string',
        'message': 'string',
        'username': 'string'
    });

    config.registerClassSerializer('PasswordDetails', {
        'id': 'number',
        'notes': 'string',
        'password': 'string',
        'title': 'string',
        'username': 'string'
    });

    config.registerClassSerializer('PasswordForm', {
        'notes': 'string',
        'password': 'string',
        'title': 'string',
        'username': 'string'
    });

    config.registerClassSerializer('PasswordInfo', {
        'id': 'number',
        'notes': 'string',
        'title': 'string',
        'username': 'string'
    });

    config.registerClassSerializer('ProjectDetails', {
        'bills': 'BillSummary[]',
        'employeeUnbilledWorkSummaries': 'EmployeeUnbilledWorkSummary[]',
        'id': 'number',
        'name': 'string',
        'roles': 'CustomerProjectRole[]',
        'unbilledTasks': 'EmployeeTaskInfo[]'
    });

    config.registerClassSerializer('ProjectId', {
        'id': 'number'
    });

    config.registerClassSerializer('ProjectSummary', {
        'billed': 'number',
        'id': 'number',
        'name': 'string',
        'totalBilling': 'number',
        'unbilled': 'number'
    });

    config.registerClassSerializer('SaveHoursRequest', {
        'date': 'LocalDate',
        'minutes': 'number',
        'notes': 'string',
        'taskId': 'number'
    });

    config.registerClassSerializer('SaveHoursResponse', {
        'minutes': 'number'
    });

    config.registerClassSerializer('SlashCommandResponse', {
        'attachments': 'Attachment[]',
        'responseType': 'ResponseType',
        'text': 'string',
        'username': 'string'
    });

    config.registerClassSerializer('TaskWeekHours', {
        'friday': 'BillableWork',
        'id': 'number',
        'monday': 'BillableWork',
        'name': 'string',
        'projectId': 'number',
        'projectName': 'string',
        'saturday': 'BillableWork',
        'sunday': 'BillableWork',
        'thursday': 'BillableWork',
        'tuesday': 'BillableWork',
        'wednesday': 'BillableWork'
    });

    config.registerClassSerializer('VacationCalendar', {
        'days': 'number[]',
        'month': 'number',
        'year': 'number'
    });

    config.registerClassSerializer('VacationSourceSummary', {
        'assignees': 'string[]',
        'created': 'LocalDate',
        'creator': 'string',
        'name': 'string',
        'numberOfDays': 'number'
    });

    config.registerClassSerializer('VacationSummary', {
        'employeeId': 'number',
        'employeeLogin': 'string',
        'employeeName': 'string',
        'totalDays': 'number',
        'unusedDays': 'number',
        'usedDays': 'number'
    });

}

export class ApinaConfig {

    /** Prefix added for all API calls */
    baseUrl: string = "";

    private serializers: SerializerMap = {
        any: identitySerializer,
        string: identitySerializer,
        number: identitySerializer,
        boolean: identitySerializer
    };

    constructor() {
        registerDefaultSerializers(this);
    }

    serialize(value: any, type: string): any {
        return this.lookupSerializer(type).serialize(value);
    }

    deserialize(value: any, type: string): any {
        return this.lookupSerializer(type).deserialize(value);
    }

    registerSerializer(name: string, serializer: Serializer) {
        this.serializers[name] = serializer;
    }

    registerEnumSerializer(name: string, enumObject: any) {
        this.registerSerializer(name, enumSerializer(enumObject));
    }

    registerClassSerializer(name: string, fields: any) {
        this.registerSerializer(name, this.classSerializer(fields));
    }

    registerIdentitySerializer(name: string) {
        this.registerSerializer(name, identitySerializer);
    }

    registerDiscriminatedUnionSerializer(name: string, discriminator: string, types: { [key: string]: string; }) {
        this.registerSerializer(name, this.discriminatedUnionSerializer(discriminator, types));
    }

    private classSerializer(fields: any): Serializer {
        function mapProperties(obj: any, propertyMapper: (value: any, type: string) => any) {
            if (obj === null || obj === undefined) {
                return obj;
            }

            const result: any = {};

            for (const name in fields) {
                if (fields.hasOwnProperty(name)) {
                    const value: any = obj[name];
                    const type: string = fields[name];
                    result[name] = propertyMapper(value, type);
                }
            }

            return result;
        }

        const serialize = this.serialize.bind(this);
        const deserialize = this.deserialize.bind(this);
        return {
            serialize(obj) {
                return mapProperties(obj, serialize);
            },
            deserialize(obj) {
                return mapProperties(obj, deserialize);
            }
        };
    }

    private discriminatedUnionSerializer(discriminatorProperty: string, types: { [key: string]: string; }): Serializer {
        const resolveSerializer = (localType: string) => {
            return this.lookupSerializer(types[localType]);
        };

        return {
            serialize(obj) {
                if (obj == null) return null;

                const localType = obj[discriminatorProperty];
                const result = resolveSerializer(localType).serialize(obj);
                result[discriminatorProperty] = localType;
                return result;
            },
            deserialize(obj) {
                if (obj == null) return null;

                const localType = obj[discriminatorProperty];
                const result = resolveSerializer(localType).deserialize(obj);
                result[discriminatorProperty] = localType;
                return result;
            }
        };
    }

    private lookupSerializer(type: string): Serializer {
        if (!type) throw new Error("no type given");

        if (type.indexOf('[]', type.length - 2) !== -1) { // type.endsWith('[]')
            const elementType = type.substring(0, type.length - 2);
            const elementSerializer = this.lookupSerializer(elementType);
            return arraySerializer(elementSerializer);
        }

        const dictionaryMatched = /^Dictionary<(.+)>$/.exec(type);
        if (dictionaryMatched) {
            return dictionarySerializer(this.lookupSerializer(dictionaryMatched[1]));
        }

        const serializer = this.serializers[type];
        if (serializer) {
            return serializer;
        } else {
            throw new Error(`could not find serializer for type '${type}'`);
        }
    }
}

function arraySerializer(elementSerializer: Serializer): Serializer {
    function safeMap(value: any[], mapper: (a: any) => any) {
        if (!value)
            return value;
        else
            return value.map(mapper);
    }

    const serialize = elementSerializer.serialize.bind(elementSerializer);
    const deserialize = elementSerializer.deserialize.bind(elementSerializer);

    return {
        serialize(value) {
            return safeMap(value, serialize);
        },
        deserialize(value) {
            return safeMap(value, deserialize);
        }
    }
}

function dictionarySerializer(valueSerializer: Serializer): Serializer {
    function safeMap(dictionary: Dictionary<any>, mapper: (a: any) => any) {
        if (!dictionary)
            return dictionary;
        else {
            const result: any = {};
            for (const key in dictionary) {
                if (dictionary.hasOwnProperty(key)) {
                    result[key] = mapper(dictionary[key])
                }
            }
            return result
        }
    }

    const serialize = valueSerializer.serialize.bind(valueSerializer);
    const deserialize = valueSerializer.deserialize.bind(valueSerializer);

    return {
        serialize(value) {
            return safeMap(value, serialize);
        },
        deserialize(value) {
            return safeMap(value, deserialize);
        }
    }
}

function formatQueryParameters(params: { [key: string]: any }): string {
    const queryParameters: string[] = [];

    const addQueryParameter = (encodedKey: string, value: any) => {
        if (value != null)
            queryParameters.push(`${encodedKey}=${encodeURIComponent(value)}`);
    };

    for (const key of Object.keys(params || {})) {
        const value = params[key];
        const encodedKey = encodeURIComponent(key);

        if (Array.isArray(value)) {
            for (const arrayItemValue of value)
                addQueryParameter(encodedKey, arrayItemValue);
        } else {
            addQueryParameter(encodedKey, value);
        }
    }

    return queryParameters.length > 0 ? '?' + queryParameters.join('&') : '';
}

export interface UrlData {
    uriTemplate: string;
    pathVariables?: any;
    requestParams?: any;
}

export interface RequestData extends UrlData {
    method: string;
    requestBody?: any;
    responseType?: string;
}

export interface Serializer {
    serialize(o: any): any;
    deserialize(o: any): any;
}

const identitySerializer: Serializer = {
    serialize(o) {
        return o;
    },
    deserialize(o) {
        return o;
    }
};

function enumSerializer(enumObject: any): Serializer {
    return {
        serialize(o) {
            if (o === null || o === undefined)
                return o;
            else
                return enumObject[o];
        },
        deserialize(o) {
            if (o === null || o === undefined)
                return o;
            else
                return enumObject[o];
        }
    }
}

interface SerializerMap {
    [name: string]: Serializer;
}

export abstract class ApinaEndpointContext {

    constructor(protected readonly config: ApinaConfig) {
    }

    abstract request(data: RequestData): Observable<any>

    url(data: UrlData): string {
        const url = this.buildUrl(data.uriTemplate, data.pathVariables);
        return url + formatQueryParameters(data.requestParams);
    }

    serialize(value: any, type: string): any {
        return this.config.serialize(value, type);
    }

    deserialize(value: any, type: string): any {
        return this.config.deserialize(value, type);
    }

    protected buildUrl(uriTemplate: String, pathVariables: any): string {
        return this.config.baseUrl + uriTemplate.replace(/{([^}]+)}/g, (_match, name) => pathVariables[name]);
    }
}

@Injectable()
export class DefaultApinaEndpointContext extends ApinaEndpointContext {

    constructor(private readonly httpClient: HttpClient, config: ApinaConfig) {
        super(config);
    }

    request(data: RequestData): Observable<any> {
        const url = this.buildUrl(data.uriTemplate, data.pathVariables);

        const requestParams = data.requestParams;
        let params: HttpParams | undefined = undefined;
        if (requestParams != null) {
            const filteredParams: { [key: string]: any }  = {};
            for (const key of Object.keys(requestParams)) {
                const value = requestParams[key];
                if (value != null)
                    filteredParams[key] = value;
            }

            params = new HttpParams({fromObject: filteredParams});
        }

        return this.httpClient.request(data.method, url, { params: params, body: data.requestBody })
            .pipe(map(r => data.responseType ? this.config.deserialize(r, data.responseType) : r));
    }
}

interface ProvideParams {
    config?: ApinaConfig;
    endpointContextClass?: Type<ApinaEndpointContext>;
}

export function provideApina(params: ProvideParams = {}): Provider[] {
    return [
        { provide: ApinaConfig, useValue: params.config ?? new ApinaConfig() },
        { provide: ApinaEndpointContext, useClass: params.endpointContextClass ?? DefaultApinaEndpointContext },
    ];
}

@Injectable({providedIn: 'root'})
export class ConferenceEndpoint {
    constructor(private readonly context: ApinaEndpointContext) {
    }

    getConferenceSheet(year: number, week: number): Observable<ConferenceSheet> {
        return this.context.request({
            'uriTemplate': '/api/conference/{year}/{week}',
            'method': 'GET',
            'pathVariables': {
                'year': this.context.serialize(year, 'number'),
                'week': this.context.serialize(week, 'number')
            },
            'responseType': 'ConferenceSheet'
        });
    }

}

@Injectable({providedIn: 'root'})
export class EmployeesEndpoint {
    constructor(private readonly context: ApinaEndpointContext) {
    }

    employeeDetails(login: string): Observable<EmployeeDetails> {
        return this.context.request({
            'uriTemplate': '/api/employee/{login}',
            'method': 'GET',
            'pathVariables': {
                'login': this.context.serialize(login, 'string')
            },
            'responseType': 'EmployeeDetails'
        });
    }

    employees(): Observable<EmployeeInfo[]> {
        return this.context.request({
            'uriTemplate': '/api/employee',
            'method': 'GET',
            'responseType': 'EmployeeInfo[]'
        });
    }

    updateEmployee(login: string, details: EmployeeDetails): Observable<EmployeeDetails> {
        return this.context.request({
            'uriTemplate': '/api/employee/{login}',
            'method': 'POST',
            'pathVariables': {
                'login': this.context.serialize(login, 'string')
            },
            'requestBody': this.context.serialize(details, 'EmployeeDetails'),
            'responseType': 'EmployeeDetails'
        });
    }

}

@Injectable({providedIn: 'root'})
export class FinnpilotEndpoint {
    constructor(private readonly context: ApinaEndpointContext) {
    }

    pilotwebDeployToProduction(): Observable<SlashCommandResponse> {
        return this.context.request({
            'uriTemplate': '/api/slack/finnpilot/pilotweb/deploy/production',
            'method': 'POST',
            'responseType': 'SlashCommandResponse'
        });
    }

    productionDbToQa(): Observable<SlashCommandResponse> {
        return this.context.request({
            'uriTemplate': '/api/slack/finnpilot/pilotweb/production-db-to-qa',
            'method': 'POST',
            'responseType': 'SlashCommandResponse'
        });
    }

}

@Injectable({providedIn: 'root'})
export class GitHubWebhookEndpoint {
    constructor(private readonly context: ApinaEndpointContext) {
    }

    webhook(payload: string): Observable<string> {
        return this.context.request({
            'uriTemplate': '/github/webhook',
            'method': 'POST',
            'requestBody': this.context.serialize(payload, 'string'),
            'responseType': 'string'
        });
    }

}

@Injectable({providedIn: 'root'})
export class GoatseEndpoint {
    constructor(private readonly context: ApinaEndpointContext) {
    }

    curse(): Observable<SlashCommandResponse> {
        return this.context.request({
            'uriTemplate': '/api/slack/goatse',
            'method': 'GET',
            'responseType': 'SlashCommandResponse'
        });
    }

}

@Injectable({providedIn: 'root'})
export class HaddockEndpoint {
    constructor(private readonly context: ApinaEndpointContext) {
    }

    curse(): Observable<SlashCommandResponse> {
        return this.context.request({
            'uriTemplate': '/api/slack/haddock',
            'method': 'GET',
            'responseType': 'SlashCommandResponse'
        });
    }

}

@Injectable({providedIn: 'root'})
export class HoursheetEndpoint {
    constructor(private readonly context: ApinaEndpointContext) {
    }

    hoursheet(user: string, year: number, week: number): Observable<Hoursheet> {
        return this.context.request({
            'uriTemplate': '/api/hoursheet/{user}/{year}/{week}',
            'method': 'GET',
            'pathVariables': {
                'user': this.context.serialize(user, 'string'),
                'year': this.context.serialize(year, 'number'),
                'week': this.context.serialize(week, 'number')
            },
            'responseType': 'Hoursheet'
        });
    }

    saveHours(user: string, request: SaveHoursRequest): Observable<SaveHoursResponse> {
        return this.context.request({
            'uriTemplate': '/api/hoursheet/{user}',
            'method': 'POST',
            'pathVariables': {
                'user': this.context.serialize(user, 'string')
            },
            'requestBody': this.context.serialize(request, 'SaveHoursRequest'),
            'responseType': 'SaveHoursResponse'
        });
    }

}

@Injectable({providedIn: 'root'})
export class KonttiEndpoint {
    constructor(private readonly context: ApinaEndpointContext) {
    }

    generateKontti(): Observable<SlashCommandResponse> {
        return this.context.request({
            'uriTemplate': '/api/slack/kontti',
            'method': 'GET',
            'responseType': 'SlashCommandResponse'
        });
    }

}

@Injectable({providedIn: 'root'})
export class LoginEndpoint {
    constructor(private readonly context: ApinaEndpointContext) {
    }

    loginDetails(): Observable<LoginDetails> {
        return this.context.request({
            'uriTemplate': '/api/login/details',
            'method': 'GET',
            'responseType': 'LoginDetails'
        });
    }

}

@Injectable({providedIn: 'root'})
export class MavenRepoSearchEndpoint {
    constructor(private readonly context: ApinaEndpointContext) {
    }

    executeSearch(): Observable<SlashCommandResponse> {
        return this.context.request({
            'uriTemplate': '/api/slack/maven-repo-search',
            'method': 'GET',
            'responseType': 'SlashCommandResponse'
        });
    }

}

@Injectable({providedIn: 'root'})
export class ObliqueStrategiesEndpoint {
    constructor(private readonly context: ApinaEndpointContext) {
    }

    curse(): Observable<SlashCommandResponse> {
        return this.context.request({
            'uriTemplate': '/api/slack/oblique-strategies',
            'method': 'GET',
            'responseType': 'SlashCommandResponse'
        });
    }

}

@Injectable({providedIn: 'root'})
export class OodleEndpoint {
    constructor(private readonly context: ApinaEndpointContext) {
    }

    curse(): Observable<SlashCommandResponse> {
        return this.context.request({
            'uriTemplate': '/api/slack/oodle',
            'method': 'GET',
            'responseType': 'SlashCommandResponse'
        });
    }

}

@Injectable({providedIn: 'root'})
export class ProjectsEndpoint {
    constructor(private readonly context: ApinaEndpointContext) {
    }

    billDetails(projectId: number, id: number): Observable<BillDetails> {
        return this.context.request({
            'uriTemplate': '/api/projects/{projectId}/bills/{id}',
            'method': 'GET',
            'pathVariables': {
                'projectId': this.context.serialize(projectId, 'number'),
                'id': this.context.serialize(id, 'number')
            },
            'responseType': 'BillDetails'
        });
    }

    createBill(projectId: number, dto: CreateBillRequest): Observable<void> {
        return this.context.request({
            'uriTemplate': '/api/projects/{projectId}/bills',
            'method': 'POST',
            'pathVariables': {
                'projectId': this.context.serialize(projectId, 'number')
            },
            'requestBody': this.context.serialize(dto, 'CreateBillRequest')
        });
    }

    createProject(project: CreateCustomerProjectRequest): Observable<ProjectId> {
        return this.context.request({
            'uriTemplate': '/api/projects',
            'method': 'POST',
            'requestBody': this.context.serialize(project, 'CreateCustomerProjectRequest'),
            'responseType': 'ProjectId'
        });
    }

    createProjectRole(projectId: number, dto: CustomerProjectRoleDto): Observable<void> {
        return this.context.request({
            'uriTemplate': '/api/projects/{projectId}/roles',
            'method': 'POST',
            'pathVariables': {
                'projectId': this.context.serialize(projectId, 'number')
            },
            'requestBody': this.context.serialize(dto, 'CustomerProjectRoleDto')
        });
    }

    projectDetails(id: number): Observable<ProjectDetails> {
        return this.context.request({
            'uriTemplate': '/api/projects/{id}',
            'method': 'GET',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'responseType': 'ProjectDetails'
        });
    }

    projectRole(projectId: number, roleId: number): Observable<CustomerProjectRoleDto> {
        return this.context.request({
            'uriTemplate': '/api/projects/{projectId}/roles/{roleId}',
            'method': 'GET',
            'pathVariables': {
                'projectId': this.context.serialize(projectId, 'number'),
                'roleId': this.context.serialize(roleId, 'number')
            },
            'responseType': 'CustomerProjectRoleDto'
        });
    }

    projects(): Observable<ProjectSummary[]> {
        return this.context.request({
            'uriTemplate': '/api/projects',
            'method': 'GET',
            'responseType': 'ProjectSummary[]'
        });
    }

    updateProjectRole(projectId: number, roleId: number, dto: CustomerProjectRoleDto): Observable<CustomerProjectRoleDto> {
        return this.context.request({
            'uriTemplate': '/api/projects/{projectId}/roles/{roleId}',
            'method': 'POST',
            'pathVariables': {
                'projectId': this.context.serialize(projectId, 'number'),
                'roleId': this.context.serialize(roleId, 'number')
            },
            'requestBody': this.context.serialize(dto, 'CustomerProjectRoleDto'),
            'responseType': 'CustomerProjectRoleDto'
        });
    }

}

@Injectable({providedIn: 'root'})
export class PublicErrorLogEndpoint {
    constructor(private readonly context: ApinaEndpointContext) {
    }

    logError(clientName: string, clientVersion: string, error: NewErrorLogEntryDto): Observable<void> {
        return this.context.request({
            'uriTemplate': '/api/public/error-log',
            'method': 'POST',
            'requestParams': {
                'clientName': this.context.serialize(clientName, 'string'),
                'clientVersion': this.context.serialize(clientVersion, 'string')
            },
            'requestBody': this.context.serialize(error, 'NewErrorLogEntryDto')
        });
    }

}

@Injectable({providedIn: 'root'})
export class PublicHaddockEndpoint {
    constructor(private readonly context: ApinaEndpointContext) {
    }

    curse(target: string): Observable<string> {
        return this.context.request({
            'uriTemplate': '/api/public/haddock',
            'method': 'GET',
            'requestParams': {
                'target': this.context.serialize(target, 'string')
            },
            'responseType': 'string'
        });
    }

}

@Injectable({providedIn: 'root'})
export class RajaniemiEndpoint {
    constructor(private readonly context: ApinaEndpointContext) {
    }

    curse(): Observable<SlashCommandResponse> {
        return this.context.request({
            'uriTemplate': '/api/slack/rajaniemi',
            'method': 'GET',
            'responseType': 'SlashCommandResponse'
        });
    }

}

@Injectable({providedIn: 'root'})
export class Rot13Endpoint {
    constructor(private readonly context: ApinaEndpointContext) {
    }

    rot13(): Observable<SlashCommandResponse> {
        return this.context.request({
            'uriTemplate': '/api/slack/rot13',
            'method': 'GET',
            'responseType': 'SlashCommandResponse'
        });
    }

}

@Injectable({providedIn: 'root'})
export class SavedPasswordEndpoint {
    constructor(private readonly context: ApinaEndpointContext) {
    }

    addPassword(form: PasswordForm): Observable<void> {
        return this.context.request({
            'uriTemplate': '/api/saved-passwords',
            'method': 'POST',
            'requestBody': this.context.serialize(form, 'PasswordForm')
        });
    }

    deletePassword(id: number): Observable<void> {
        return this.context.request({
            'uriTemplate': '/api/saved-passwords/{id}',
            'method': 'DELETE',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            }
        });
    }

    getPasswordDetails(id: number): Observable<PasswordDetails> {
        return this.context.request({
            'uriTemplate': '/api/saved-passwords/{id}',
            'method': 'GET',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'responseType': 'PasswordDetails'
        });
    }

    listPasswords(): Observable<PasswordInfo[]> {
        return this.context.request({
            'uriTemplate': '/api/saved-passwords',
            'method': 'GET',
            'responseType': 'PasswordInfo[]'
        });
    }

    updatePasswordDetails(id: number, form: PasswordForm): Observable<void> {
        return this.context.request({
            'uriTemplate': '/api/saved-passwords/{id}',
            'method': 'PUT',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'requestBody': this.context.serialize(form, 'PasswordForm')
        });
    }

}

@Injectable({providedIn: 'root'})
export class ShakespeareEndpoint {
    constructor(private readonly context: ApinaEndpointContext) {
    }

    curse(): Observable<SlashCommandResponse> {
        return this.context.request({
            'uriTemplate': '/api/slack/shakespeare',
            'method': 'GET',
            'responseType': 'SlashCommandResponse'
        });
    }

}

@Injectable({providedIn: 'root'})
export class TietokeskusEndpoint {
    constructor(private readonly context: ApinaEndpointContext) {
    }

    receiveRequest(): Observable<void> {
        return this.context.request({
            'uriTemplate': '/api/slack/tietokeskus',
            'method': 'GET'
        });
    }

}

@Injectable({providedIn: 'root'})
export class VCardExportEndpoint {
    constructor(private readonly context: ApinaEndpointContext) {
    }

    employeesVCard(): Observable<string> {
        return this.context.request({
            'uriTemplate': '/employees.vcf',
            'method': 'GET',
            'responseType': 'string'
        });
    }

}

@Injectable({providedIn: 'root'})
export class VacationsEndpoint {
    constructor(private readonly context: ApinaEndpointContext) {
    }

    calendar(login: string, year: number, month: number): Observable<VacationCalendar> {
        return this.context.request({
            'uriTemplate': '/api/vacations/{login}/calendar/{year}/{month}',
            'method': 'GET',
            'pathVariables': {
                'login': this.context.serialize(login, 'string'),
                'year': this.context.serialize(year, 'number'),
                'month': this.context.serialize(month, 'number')
            },
            'responseType': 'VacationCalendar'
        });
    }

    employeeSummary(login: string): Observable<VacationSummary> {
        return this.context.request({
            'uriTemplate': '/api/vacations/{login}/summary',
            'method': 'GET',
            'pathVariables': {
                'login': this.context.serialize(login, 'string')
            },
            'responseType': 'VacationSummary'
        });
    }

    employeeVacationSourceSummaries(login: string): Observable<EmployeeVacationSourceSummary[]> {
        return this.context.request({
            'uriTemplate': '/api/vacations/{login}/sources',
            'method': 'GET',
            'pathVariables': {
                'login': this.context.serialize(login, 'string')
            },
            'responseType': 'EmployeeVacationSourceSummary[]'
        });
    }

    saveVacationSource(dto: CreateVacationSourceDto): Observable<void> {
        return this.context.request({
            'uriTemplate': '/api/vacations/sources',
            'method': 'POST',
            'requestBody': this.context.serialize(dto, 'CreateVacationSourceDto')
        });
    }

    toggleVacation(login: string, year: number, month: number, day: number, state: string): Observable<VacationSummary> {
        return this.context.request({
            'uriTemplate': '/api/vacations/{login}/calendar/{year}/{month}/{day}',
            'method': 'POST',
            'pathVariables': {
                'login': this.context.serialize(login, 'string'),
                'year': this.context.serialize(year, 'number'),
                'month': this.context.serialize(month, 'number'),
                'day': this.context.serialize(day, 'number')
            },
            'requestBody': this.context.serialize(state, 'string'),
            'responseType': 'VacationSummary'
        });
    }

    vacationSourceSummaries(): Observable<VacationSourceSummary[]> {
        return this.context.request({
            'uriTemplate': '/api/vacations/sources',
            'method': 'GET',
            'responseType': 'VacationSourceSummary[]'
        });
    }

    vacationSummaries(): Observable<VacationSummary[]> {
        return this.context.request({
            'uriTemplate': '/api/vacations',
            'method': 'GET',
            'responseType': 'VacationSummary[]'
        });
    }

}

