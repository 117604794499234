import {ApinaConfig, ApinaEndpointContext, RequestData} from "../apina";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {loginService} from "./login.service";
import {map} from "rxjs/operators";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";

// TODO Apina does not yet provide a good API for configuring tokens etc. so we'll have this hack instead.
@Injectable()
export class MyApinaEndpointContext extends ApinaEndpointContext {
    constructor(private _http: HttpClient, private _config: ApinaConfig) {
        super(_config);
    }

    request(data: RequestData): Observable<any> {
        const url = this.buildUrl(data.uriTemplate, data.pathVariables);

        const requestParams = data.requestParams;
        let params: HttpParams | undefined = undefined;
        if (requestParams != null) {
            const filteredParams: { [key: string]: any }  = {};
            for (const key of Object.keys(requestParams)) {
                const value = requestParams[key];
                if (value != null)
                    filteredParams[key] = value;
            }

            params = new HttpParams({fromObject: filteredParams});
        }

        let headers = new HttpHeaders();
        const authToken = loginService.authenticationToken;
        if (authToken)
            headers = headers.set('X-Auth-Token', authToken);

        return this._http.request(data.method, url, { params, body: data.requestBody, headers })
            .pipe(map(r => data.responseType ? this.deserialize(r, data.responseType) : r));
    }

    protected override buildUrl(uriTemplate: String, pathVariables: any): string {
        return this._config.baseUrl + uriTemplate.replace(/\{([^}]+)}/g, (match, name) => pathVariables[name]);
    }
}

