import {Component, Inject} from "@angular/core";
import {PasswordInfo, SavedPasswordEndpoint} from "../apina";
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";

@Component({
    templateUrl: "./delete-password.component.html",
    imports: [
        MatDialogModule,
        MatButtonModule,
    ],
    standalone: true,
})
export class DeletePasswordComponent {

    constructor(private readonly dialogRef: MatDialogRef<DeletePasswordComponent>,
                private readonly savedPasswordEndpoint: SavedPasswordEndpoint,
                @Inject(MAT_DIALOG_DATA) readonly password: PasswordInfo) {
    }

    confirmDelete() {
        this.savedPasswordEndpoint.deletePassword(this.password.id).subscribe(() => {
            this.dialogRef.close();
        });
    }

    dismiss() {
        this.dialogRef.close();
    }
}
