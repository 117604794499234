import {Component} from "@angular/core";
import {ProjectsEndpoint, ProjectSummary} from "../apina";
import {NewProjectComponent} from "./new-project.component";
import {MatDialog} from "@angular/material/dialog";
import {EurosPipe} from "../pipes/euros.pipe";
import {RouterLink} from "@angular/router";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";

@Component({
    templateUrl: "./project-list.component.html",
    styleUrls: ["./project-list.component.scss"],
    imports: [
        EurosPipe,
        RouterLink,
        MatIconModule,
        MatButtonModule,
        MatProgressBarModule,
    ],
    standalone: true,
})
export class ProjectListComponent {

    projects: ProjectSummary[];
    totalBilled: number;
    totalUnbilled: number;
    totalBilling: number;

    constructor(private readonly matDialog: MatDialog,
                projectsEndpoint: ProjectsEndpoint) {
        projectsEndpoint.projects().subscribe(projects => {
            this.projects = projects;
            this.totalBilled = 0;
            this.totalUnbilled = 0;
            this.totalBilling = 0;

            for (const p of projects) {
               this.totalBilled += p.billed;
               this.totalUnbilled += p.unbilled;
               this.totalBilling += p.totalBilling;
            }
        });
    }

    addProject() {
        this.matDialog.open(NewProjectComponent);
    }
}
