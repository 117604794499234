import {TimeError} from "./time-error";

export const minYear = -999999999;
export const maxYear = 999999999;
export const minWeekOfYear = 1;
export const minHour = 0;
export const maxHour = 23;

export function isLeapYear(year: number) {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
}

// Returns ISO weekday between 1 (Monday) and 7 (Sunday).
export function weekdayOfDate(year: number, month: number, day: number): number {
    return ((new Date(year, month - 1, day).getDay() + 6) % 7) + 1;
}

export function maxWeekOfYear(year: number) {
    const lastDayOYear = weekdayOfDate(year, 12, 31);
    const yearEndsOnThursday = lastDayOYear === 4;
    const leapYearEndsOnFriday = isLeapYear(year) && lastDayOYear === 5;

    return yearEndsOnThursday || leapYearEndsOnFriday ? 53 : 52;
}

export function checkValidInteger(valueName: string, value: number) {
    if (value === undefined || value === null) {
        throw new TimeError('UndefinedArgumentError', `Undefined argument: ${valueName}`);
    }

    if (!Number.isInteger(value)) {
        throw new TimeError('NonIntegerError', `${valueName} is not an integer: ${value}`);
    }
}

export function checkIntegerIsInRange(valueName: string, value: number, min: number, max: number) {
    checkValidInteger(valueName, value);

    if (value < min || value > max) {
        throw new TimeError('IntegerRangeError', `${valueName} (${value}) not in valid range: ${min}-${max}`);
    }
}

export function checkValidYear(year: number) {
    checkIntegerIsInRange('Year', year, minYear, maxYear);
}

export function checkValidWeekOfYear(weekOfYear: number, year: number) {
    checkIntegerIsInRange('Week of year', weekOfYear, minWeekOfYear, maxWeekOfYear(year));
}

export function checkValidHourInDay(hour: number) {
    checkIntegerIsInRange('Hour', hour, minHour, maxHour);
}

export function pad(n: number, numberOfDigits: number): string {
    let str = n.toString(10);

    while (str.length < numberOfDigits)
        str = '0' + str;

    return str;
}
