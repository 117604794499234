import {Pipe, PipeTransform} from "@angular/core";
import {DateTimeFormatter, TemporalAccessor} from "js-joda";

const timeFormat = DateTimeFormatter.ofPattern("H:mm");

@Pipe({
    name: 'localTime',
    pure: true,
    standalone: true
})
export class LocalTimePipe implements PipeTransform {
    transform(value: TemporalAccessor | null): string | null {
        return value && timeFormat.format(value);
    }
}
