import {Component} from "@angular/core";
import {CreateCustomerProjectRequest, ProjectsEndpoint} from "../apina";
import {Router} from "@angular/router";
import {MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {FormsModule} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";

@Component({
    templateUrl: "./new-project.component.html",
    imports: [
        FormsModule,
        MatButtonModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
    ],
    standalone: true
})
export class NewProjectComponent {

    project: CreateCustomerProjectRequest = {
        name: ''
    };

    constructor(private readonly dialogRef: MatDialogRef<NewProjectComponent>,
                private readonly router: Router,
                private readonly projectsEndpoint: ProjectsEndpoint) {
    }

    save() {
        this.projectsEndpoint.createProject(this.project).subscribe(result => {
            this.dialogRef.close();
            this.router.navigateByUrl('/projects/' + result.id);
        });
    }

    dismiss() {
        this.dialogRef.close();
    }
}
