import {Component} from "@angular/core";
import {ConferenceEndpoint, ConferenceSheet as ApiConferenceSheet} from "../apina";
import {Inclusivity, LocalDateTimeRange, LocalTimeRange, WeekOfYear} from "../utils/time";
import {createLocalTimeStepsBetween} from '../utils/time-utils';
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {Duration, LocalDate, LocalDateTime, LocalTime} from "js-joda";
import {NgStyle} from "@angular/common";
import {LocalTimePipe} from "../pipes/local-time.pipe";
import {MatProgressBar} from "@angular/material/progress-bar";
import {ShortDayComponent} from "../components/short-day.component";
import {ShortLocalDatePipe} from "../pipes/short-local-date.pipe";

const pixelsPerMinute = 1;
const subsectionsInOneHour = 2;

@Component({
    templateUrl: "./conference.component.html",
    styleUrls: ["./conference.component.scss"],
    imports: [
        NgStyle,
        RouterLink,
        LocalTimePipe,
        MatProgressBar,
        ShortDayComponent,
        ShortLocalDatePipe,
    ],
    standalone: true,
})
export class ConferenceComponent {

    year: number;
    week: number;
    conferenceSheet: ConferenceSheet;

    timelineSlotTimes: LocalTime[];
    timelineSlotHeight: number = pixelsPerMinute * 60;

    slotTimes: LocalTime[];
    slotHeight: number = pixelsPerMinute * 60 / subsectionsInOneHour;

    constructor(route: ActivatedRoute,
                router: Router,
                private conferenceEndpoints: ConferenceEndpoint) {
        route.params.forEach(p => {
            if (p['year'] === undefined || p['week'] === undefined) {
                const weekOfYear = WeekOfYear.now();
                router.navigate(["/conference", weekOfYear.year, weekOfYear.week]);
                return;
            }

            this.year = +p['year'];
            this.week = +p['week'];

            const dayInterval = LocalTimeRange.betweenHours(8, 22);

            this.conferenceEndpoints.getConferenceSheet(this.year, this.week).subscribe(cs => {
                this.conferenceSheet = new ConferenceSheet(cs, dayInterval);
            });

            this.timelineSlotTimes = createLocalTimeStepsBetween(dayInterval, 60);
            this.slotTimes = createLocalTimeStepsBetween(dayInterval, 60 / subsectionsInOneHour);
        });
    }

    get days(): ConferenceDay[] {
        return this.conferenceSheet.days;
    }
}

class ConferenceSheet {

    weekOfYear: WeekOfYear;
    nextWeek: WeekOfYear;
    previousWeek: WeekOfYear;
    days: ConferenceDay[];

    constructor(sheet: ApiConferenceSheet, public dayInterval: LocalTimeRange) {

        this.weekOfYear = sheet.weekOfYear;
        this.nextWeek = this.weekOfYear.nextWeek();
        this.previousWeek = this.weekOfYear.previousWeek();
        const monday = this.weekOfYear.monday();
        this.days = [0, 1, 2, 3, 4, 5, 6].map(d => new ConferenceDay(monday.plusDays(d), dayInterval));

        for (let sheetEvent of sheet.events) {

            let range = new LocalDateTimeRange(sheetEvent.start, sheetEvent.end);
            let event = new ConferenceEvent(range, sheetEvent.description);

            for (let day of this.days) {
                if (day.overlaps(event))
                    day.add(new ConferenceEventBox(event, day.date, day.timeInterval));
            }
        }

        for (let day of this.days) {
            day.calculateEventBoxDimensions();
        }
    }
}

class ConferenceDay {

    eventBoxes: ConferenceEventBox[] = [];

    constructor(public date: LocalDate, public timeInterval: LocalTimeRange) {
    }

    overlaps(event: ConferenceEvent): boolean {
        return this.interval.overlaps(event.interval);
    }

    add(eventBox: ConferenceEventBox): void {
        this.eventBoxes.push(eventBox);
    }

    get interval(): LocalDateTimeRange {
        return this.timeInterval.atDate(this.date);
    }

    calculateEventBoxDimensions(): void {

         /*
             Algorithm expects event boxes to be in ascending start time order.
             Boxes are left indented by amount of all boxes above in hierarchy that it overlaps directly or indirectly.
             Boxes are right indented by maximum depth of directly or indirectly overlapping boxes below.

             Difference to google layout algorithm:

             Google    This
             A         A
             A B       A B
               B         B
             C B         B C
             C B E       B C E
               B         B
             D B         B D D
             D             D D
         */

        const overlapCandidatesStack: ConferenceEventBox[] = [];

        for (let eventBox of this.eventBoxes) {

            let overlapsAboveInHierarchy = 0;
            let eventBoxToCheckAgainst = eventBox;

            for (let i = overlapCandidatesStack.length - 1; i >= 0; i--) {

                const overlapCandidate = overlapCandidatesStack[i];

                if (overlapCandidate.overlaps(eventBoxToCheckAgainst)) {
                    overlapsAboveInHierarchy++;
                    overlapCandidate.indentRight = Math.max(overlapsAboveInHierarchy, overlapCandidate.indentRight);
                    eventBoxToCheckAgainst = overlapCandidate;
                } else {
                    overlapCandidatesStack.pop();
                }
            }

            eventBox.indentLeft = overlapsAboveInHierarchy;
            overlapCandidatesStack.push(eventBox);
        }
    }
}
class ConferenceEvent {

    focus: boolean;

    constructor(public interval: LocalDateTimeRange, public description: string) {
    }
}

class ConferenceEventBox {

    private interval: LocalTimeRange;
    indentLeft  = 0;
    indentRight = 0;

    constructor(public event: ConferenceEvent, private date: LocalDate, private dayInterval: LocalTimeRange) {
        this.interval = event.interval.limit(dayInterval.atDate(date)).toLocalTimeRange();
    }

    get focus(): boolean {
        return this.event.focus;
    }

    set focus(focus: boolean) {
        this.event.focus = focus;
    }

    get description(): string {
        return this.event.description;
    }

    get duration(): Duration {
        return this.interval.duration;
    }

    get minutesFromStartOfTheDay(): number {
        return new LocalTimeRange(this.dayInterval.start, this.interval.start).duration.toMinutes();
    }

    overlaps(eventBox: ConferenceEventBox): boolean {
        return this.interval.overlaps(eventBox.interval, Inclusivity.EXCLUSIVE);
    }

    showStartDay(): boolean {
        return !this.eventStart.toLocalDate().isEqual(this.date);
    }

    showEndDay(): boolean {
        return !this.eventEnd.toLocalDate().isEqual(this.date);
    }

    get eventStart(): LocalDateTime {
        return this.event.interval.start;
    }

    get eventEnd(): LocalDateTime {
        return this.event.interval.end;
    }

    get styles() {
        const maxWidthPercentage = 90;
        const borderAndMargin = 3;
        const indentationPercentage = 15;

        return {
            height: this.duration.toMinutes() * pixelsPerMinute - borderAndMargin + 'px',
            top: this.minutesFromStartOfTheDay * pixelsPerMinute + 'px',
            left: this.indentLeft * indentationPercentage + '%',
            width: maxWidthPercentage - (this.indentLeft + this.indentRight) * indentationPercentage + '%',
            'z-index': this.focus ? 1000 : this.indentLeft
        };
    }
}
