import {ChronoField, IsoFields, LocalDate} from "js-joda";
import {TimeError} from "./time-error";
import {checkValidWeekOfYear, checkValidYear, pad} from "./utils";

// TODO: Unit testing
const parseISOPattern = /(\d{4})-W(\d{2})/;

export class WeekOfYear {

    static now(): WeekOfYear {
        return this.fromDate(LocalDate.now());
    }

    static parseISO(o: string): WeekOfYear {

        const result = parseISOPattern.exec(o);

        if (!result) {
            throw new TimeError('ParseError', `WeekOfYear cannot be parsed from: ${o}`);
        }

        const year = parseInt(result[1], 10);
        const week = parseInt(result[2], 10);

        return new WeekOfYear(year, week);
    }

    static fromDate(date: LocalDate) {
        return new WeekOfYear(date.get(IsoFields.WEEK_BASED_YEAR), date.get(IsoFields.WEEK_OF_WEEK_BASED_YEAR));
    }

    constructor(readonly year: number, readonly week: number) {
        checkValidYear(year);
        checkValidWeekOfYear(week, year);
    }

    nextWeek(): WeekOfYear {
        return WeekOfYear.fromDate(this.monday().plusWeeks(1));
    }

    previousWeek(): WeekOfYear {
        return WeekOfYear.fromDate(this.monday().minusWeeks(1));
    }

    monday(): LocalDate {
        return LocalDate.now()
            .with(IsoFields.WEEK_BASED_YEAR, this.year)
            .with(IsoFields.WEEK_OF_WEEK_BASED_YEAR, this.week)
            .with(ChronoField.DAY_OF_WEEK, 1);
    }

    toISOString() {
        return this.year + "-W" + pad(this.week, 2);
    }

    toString() {
        return this.year + "-" + pad(this.week, 2);
    }
}
