export function generatePassword(): string {
    const options = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let pass = "";

    for (let i = 0; i < 16; i++) {
        pass += options[Math.floor(options.length * Math.random())];
    }

    return pass;
}
