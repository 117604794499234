import {Component, Inject} from "@angular/core";
import {PasswordForm, SavedPasswordEndpoint} from "../apina";
import {generatePassword} from "./password-utils";
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {FormsModule} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";

@Component({
    templateUrl: "./edit-password.component.html",
    imports: [
        FormsModule,
        MatButtonModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatProgressSpinnerModule,
    ],
    standalone: true,
})
export class EditPasswordComponent {

    form: PasswordForm;

    constructor(private readonly dialogRef: MatDialogRef<EditPasswordComponent>,
                private readonly savedPasswordEndpoint: SavedPasswordEndpoint,
                @Inject(MAT_DIALOG_DATA) private readonly passwordId: number) {
        this.savedPasswordEndpoint.getPasswordDetails(this.passwordId).subscribe(pw => this.form = pw);
    }

    savePassword() {
        this.savedPasswordEndpoint.updatePasswordDetails(this.passwordId, this.form).subscribe(() => {
            this.dialogRef.close();
        });
    }

    dismiss() {
        this.dialogRef.close();
    }

    generatePassword() {
        this.form.password = generatePassword();
    }
}
