import {Component, Inject} from "@angular/core";
import {CreateBillRequest, EmployeeInfo, EmployeesEndpoint} from "../apina";
import {ProjectService} from "./project.service";
import {LocalDate} from "js-joda";
import {loginService} from "../auth/login.service";
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {FormsModule} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatButtonModule} from "@angular/material/button";
import {MatInputModule} from "@angular/material/input";
import {MatSelectModule} from "@angular/material/select";

@Component({
    templateUrl: "./new-bill.component.html",
    imports: [
        FormsModule,
        MatButtonModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
    ],
    standalone: true,
})
export class NewBillComponent {

    bill: CreateBillRequest = {
        responsible: null,
        lastIncludedDate: LocalDate.now()
    };

    employees: EmployeeInfo[];

    constructor(private readonly dialogRef: MatDialogRef<NewBillComponent>,
                employeesEndpoint: EmployeesEndpoint,
                private readonly projectService: ProjectService,
                @Inject(MAT_DIALOG_DATA) readonly projectId: number) {
        employeesEndpoint.employees().subscribe(employees => this.employees = employees);

        this.bill.responsible = loginService.login;
    }

    dismiss() {
        this.dialogRef.close();
    }

    get lastIncludedDate() {
        return this.bill.lastIncludedDate ? this.bill.lastIncludedDate.toString() : null;
    }

    set lastIncludedDate(v: string) {
        this.bill.lastIncludedDate = v ? LocalDate.parse(v) : null;
    }

    save(): void {
        this.projectService.createBill(this.projectId, this.bill.responsible, this.bill.lastIncludedDate).subscribe(() => {
            this.dialogRef.close(true);
        });
    }
}
