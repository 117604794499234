import {Component} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {ProjectDetails, ProjectsEndpoint} from "../apina";
import {NewBillComponent} from "./new-bill.component";
import {EditProjectRoleComponent, EditProjectRoleComponentParams} from "./edit-project-role.component";
import {ActivatedRoute, RouterLink} from "@angular/router";
import {MatTabsModule} from "@angular/material/tabs";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatButtonModule} from "@angular/material/button";
import {LocalDatePipe} from "../pipes/local-date.pipe";
import {EurosPipe} from "../pipes/euros.pipe";
import {DurationPipe} from "../pipes/duration.pipe";

@Component({
    templateUrl: "./view-project.component.html",
    styleUrls: ["./view-project.component.scss"],
    imports: [
        MatTabsModule,
        MatProgressBarModule,
        MatButtonModule,
        LocalDatePipe,
        EurosPipe,
        DurationPipe,
        RouterLink,
    ],
    standalone: true,
})
export class ViewProjectComponent {

    projectId: number;
    project: ProjectDetails;

    constructor(route: ActivatedRoute,
                private readonly matDialog: MatDialog,
                private readonly projectsEndpoint: ProjectsEndpoint) {
        route.params.forEach(p => {
            this.projectId = +p['projectId'];
            this.loadData();
        });
    }

    addRole() {
        const modalRef = this.matDialog.open(EditProjectRoleComponent, {data: <EditProjectRoleComponentParams>{projectId: this.projectId}});
        modalRef.afterClosed().subscribe(() => {
            this.loadData();
        });
    }

    editRole(id: number) {
        const modalRef = this.matDialog.open(EditProjectRoleComponent, {data: <EditProjectRoleComponentParams>{projectId: this.projectId, roleId: id}});
        modalRef.afterClosed().subscribe(() => {
            this.loadData();
        });
    }

    showNewBillDialog() {
        const modalRef = this.matDialog.open(NewBillComponent, {data: this.projectId});
        modalRef.afterClosed().subscribe(() => {
            this.loadData();
        });
    }

    private loadData() {
        this.projectsEndpoint.projectDetails(this.projectId).subscribe(project => this.project = project);
    }
}
