<button class="default-fab" mat-fab color="primary" (click)="showAddForm()">
    <mat-icon>add</mat-icon>
</button>

<div>
    <mat-form-field floatLabel="always" subscriptSizing="dynamic">
        <input matInput type="search" placeholder="Filter passwords" autofocus [(ngModel)]="filter">
    </mat-form-field>
</div>

@if (passwords) {
    <table class="default-table">
        <thead>
        <tr>
            <th>Title</th>
            <th>Username</th>
            <th>Password</th>
            <th></th>
        </tr>
        </thead>
        <tbody>
            @for (pw of filteredPasswords; track pw.id) {
                <tr>
                    <td title="{{pw.notes}}">{{ pw.title }}</td>
                    <td>{{ pw.username }}</td>
                    <td>
                        @if (pw.password != null) {
                            {{ pw.password }}
                        } @else if (pw.loading) {
                            <span>loading...</span>
                        } @else {
                            <button class="reveal" mat-button (click)="togglePassword(pw)">Reveal</button>
                        }
                    </td>
                    <td class="text-right">
                        <button mat-icon-button (click)="showEditForm(pw)">
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button mat-icon-button (click)="deletePassword(pw)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </td>
                </tr>
            }
        </tbody>
    </table>
} @else {
    <mat-progress-bar/>
}
