@if (form) {
    <form #passwordForm="ngForm" novalidate class="default-form">
        <h2 mat-dialog-title>Edit password</h2>
        <mat-dialog-content>
            <div style="display: flex; flex-direction: column; gap: 8px">
                <mat-form-field floatLabel="always" subscriptSizing="dynamic">
                    <mat-label>Title</mat-label>
                    <input matInput type="text" name="title" [(ngModel)]="form.title" required autofocus>
                </mat-form-field>
                <mat-form-field floatLabel="always" subscriptSizing="dynamic">
                    <mat-label>Username</mat-label>
                    <input matInput type="text" name="username" [(ngModel)]="form.username">
                </mat-form-field>
                <mat-form-field floatLabel="always" subscriptSizing="dynamic">
                    <mat-label>Password</mat-label>
                    <input matInput type="text" name="password" [(ngModel)]="form.password">
                    <button mat-button matSuffix (click)="generatePassword()" tabindex="-1">Generate</button>
                </mat-form-field>
                <mat-form-field floatLabel="always" subscriptSizing="dynamic">
                    <mat-label>Notes</mat-label>
                    <textarea matInput name="notes" [(ngModel)]="form.notes" rows="5"></textarea>
                </mat-form-field>
            </div>
        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <button mat-button (click)="dismiss()">Cancel</button>
            <button mat-button type="submit" [disabled]="passwordForm.invalid" (click)="savePassword()">Save changes</button>
        </mat-dialog-actions>
    </form>
} @else {
    <mat-spinner/>
}
