import {VacationsEndpoint, VacationSourceSummary} from "../apina";
import {Component} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {NewVacationSourceComponent} from "./new-vacation-source.component";
import {LocalDatePipe} from "../pipes/local-date.pipe";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";

@Component({
    templateUrl: "./vacation-sources.component.html",
    imports: [
        LocalDatePipe,
        MatButtonModule,
        MatIconModule,
        MatProgressBarModule,
    ],
    standalone: true,
})
export class VacationSourcesComponent {

    vacationSources: VacationSourceSummary[];

    constructor(private readonly vacationsEndpoint: VacationsEndpoint,
                private readonly matDialog: MatDialog) {
        this.load();
    }

    private load() {
        this.vacationsEndpoint.vacationSourceSummaries().subscribe(vs => this.vacationSources = vs);
    }

    add() {
        this.matDialog.open(NewVacationSourceComponent)
            .afterClosed().subscribe(() => this.load())
    }
}
