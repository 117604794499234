@if (projects) {
    <section>
        <table class="default-table projects" style="width: 100%">
            <thead>
            <tr>
                <th>Name</th>
                <th>Billed</th>
                <th>Unbilled</th>
                <th>Total billing</th>
            </tr>
            </thead>
            <tfoot>
            <tr>
                <th>Total</th>
                <td>{{ totalBilled | euros }}</td>
                <td>{{ totalUnbilled | euros }}</td>
                <td>{{ totalBilling | euros }}</td>
            </tr>
            </tfoot>
            <tbody>
                @for (project of projects; track project.id) {
                    <tr>
                        <td><a routerLink="/projects/{{project.id}}">{{ project.name }}</a></td>
                        <td>{{ project.billed | euros }}</td>
                        <td>{{ project.unbilled | euros }}</td>
                        <td>{{ project.totalBilling | euros }}</td>
                    </tr>
                }
            </tbody>
        </table>

        <button class="default-fab" mat-fab color="primary" (click)="addProject()">
            <mat-icon>add</mat-icon>
        </button>
    </section>
} @else {
    <mat-progress-bar/>
}
