// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error#Custom_Error_Types

export class ErrorClass extends Error {

    override stack: string = (<any> new Error()).stack;

    constructor(name: string, message?: string) {
        super();
        this.name = name;
        this.message = message;
    }
}
