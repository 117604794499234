@if (hoursheet) {
    <table class="hoursheet">
        <caption>
            <a class="previous-week"
               routerLink="/hoursheet/{{hoursheet.previousWeek.year}}/{{hoursheet.previousWeek.week}}">Week {{ hoursheet.previousWeek.week }}</a>
            {{ hoursheet.weekOfYear.year }}-{{ hoursheet.weekOfYear.week }}
            <a class="next-week"
               routerLink="/hoursheet/{{hoursheet.nextWeek.year}}/{{hoursheet.nextWeek.week}}">Week {{ hoursheet.nextWeek.week }}</a>
        </caption>
        <thead>
        <tr>
            <th></th>
            <th>Mon
                <local-date [date]="hoursheet.mondayDate"></local-date>
            </th>
            <th>Tue
                <local-date [date]="hoursheet.tuesdayDate"></local-date>
            </th>
            <th>Wed
                <local-date [date]="hoursheet.wednesdayDate"></local-date>
            </th>
            <th>Thu
                <local-date [date]="hoursheet.thursdayDate"></local-date>
            </th>
            <th>Fri
                <local-date [date]="hoursheet.fridayDate"></local-date>
            </th>
            <th [hidden]="!showWeekend">Sat
                <local-date [date]="hoursheet.saturdayDate"></local-date>
            </th>
            <th [hidden]="!showWeekend">Sun
                <local-date [date]="hoursheet.sundayDate"></local-date>
            </th>
            <th></th>
        </tr>
        </thead>
        <tfoot>
        <tr>
            <td></td>
            <td>
                <app-hour-total [value]="dayTotal('monday')"/>
            </td>
            <td>
                <app-hour-total [value]="dayTotal('tuesday')"/>
            </td>
            <td>
                <app-hour-total [value]="dayTotal('wednesday')"/>
            </td>
            <td>
                <app-hour-total [value]="dayTotal('thursday')"/>
            </td>
            <td>
                <app-hour-total [value]="dayTotal('friday')"/>
            </td>
            <td [hidden]="!showWeekend">
                <app-hour-total [value]="dayTotal('saturday')"/>
            </td>
            <td [hidden]="!showWeekend">
                <app-hour-total [value]="dayTotal('sunday')"/>
            </td>
            <td>
                <app-hour-total [value]="total()"/>
            </td>
        </tr>
        </tfoot>
        <tbody>
            @for (task of hoursheet.tasks; track task.id) {
                <tr>
                    <th>{{ task.name }} <small><a routerLink="/projects/{{task.projectId}}">({{ task.projectName }})</a></small>
                    </th>
                    <td>
                        <app-hour-editor [taskId]="task.id" [date]="hoursheet.mondayDate" [hourModel]="task.monday"/>
                    </td>
                    <td>
                        <app-hour-editor [taskId]="task.id" [date]="hoursheet.tuesdayDate" [hourModel]="task.tuesday"/>
                    </td>
                    <td>
                        <app-hour-editor [taskId]="task.id" [date]="hoursheet.wednesdayDate" [hourModel]="task.wednesday"/>
                    </td>
                    <td>
                        <app-hour-editor [taskId]="task.id" [date]="hoursheet.thursdayDate" [hourModel]="task.thursday"/>
                    </td>
                    <td>
                        <app-hour-editor [taskId]="task.id" [date]="hoursheet.fridayDate" [hourModel]="task.friday"/>
                    </td>
                    <td [hidden]="!showWeekend">
                        <app-hour-editor [taskId]="task.id" [date]="hoursheet.saturdayDate" [hourModel]="task.saturday"/>
                    </td>
                    <td [hidden]="!showWeekend">
                        <app-hour-editor [taskId]="task.id" [date]="hoursheet.sundayDate" [hourModel]="task.sunday"/>
                    </td>
                    <td>
                        <app-hour-total [value]="taskTotal(task)"></app-hour-total>
                    </td>
                </tr>
            }
        </tbody>
    </table>

    <div style="text-align: right">
        <mat-checkbox [(ngModel)]="showWeekend">Show weekend</mat-checkbox>
    </div>
} @else {
    <mat-progress-bar/>
}
