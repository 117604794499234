import {Pipe, PipeTransform} from "@angular/core";
import {DateTimeFormatter, TemporalAccessor} from "js-joda";

const dateFormat = DateTimeFormatter.ofPattern("dd.MM.");

@Pipe({
    name: 'shortLocalDate',
    pure: true,
    standalone: true
})
export class ShortLocalDatePipe implements PipeTransform {
    transform(value: TemporalAccessor | null) {
        return value && dateFormat.format(value);
    }
}
